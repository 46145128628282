import React, { useState } from 'react'
import { useAdminUserTickets } from '_hooks/useAdminUserTickets'
import { Spinner } from 'components/common/Spinner'
import { NewYorkTz, StockHelper } from 'predictagram-lib'
import { IAdminUserTicketsResponse, IAdminUserTicketsSearchOptions } from 'services/AdminUserTicketsApiService'
import { useExcelDownload } from '_hooks/useExcelDownload'
import {StartEndDateFilter} from "../common/filters/StartEndDateFilter";

export const AdminTicketsList: React.FunctionComponent = () => {

  const today = StockHelper.workingHours(new Date());
  const initialStartTime = today.start;
  const initialEndTime = today.end;

  const [startDate, setStartDate] = useState<Date>(initialStartTime)
  const [endDate, setEndDate] = useState<Date>(initialEndTime)

  const initialFilter: IAdminUserTicketsSearchOptions = {
    endTime: initialEndTime.getTime() / 1000,
    startTime: initialStartTime.getTime() / 100
  }

  const ticketsApi = useAdminUserTickets(initialFilter);

  const mapper = (item: IAdminUserTicketsResponse) => {
    return {
      ...item,
    }
  }  
  const excelDownload = useExcelDownload<IAdminUserTicketsResponse>(mapper, 'tickets');
  
  const searchHandler = () => {
    const filter: IAdminUserTicketsSearchOptions = {
      endTime: endDate.getTime() / 1000,
      startTime: startDate.getTime() / 1000,
    }
    ticketsApi.setFilter(filter);
  }

  return (
    <div>
      <div className="d-flex justify-content-start align-items-center gap-2">
        <StartEndDateFilter
          startDate={new Date()}
          onStartDateChange={(date: Date) => {
            setStartDate(date);
          }}
          endDate={new Date()}
          onEndDateChange={(date: Date) => {
            setEndDate(date);
          }} />

        <button type="button" className="btn btn-primary" onClick={searchHandler}>Search</button>

        {ticketsApi.apiState.isLoaded && ticketsApi.data && ticketsApi.data.length > 0 && 
          <>{excelDownload.render(ticketsApi.data)}</>
        }

      </div>

      {ticketsApi.apiState.isLoading &&
        <>
          <Spinner />
        </>
      }

      {ticketsApi.apiState.isLoaded && ticketsApi.data?.length === 0 &&
        <div className="alert alert-warning p-5 d-flex justify-content-center">No Data</div>
      }

      {ticketsApi.apiState.isLoaded && ticketsApi.data && ticketsApi.data?.length > 0 &&
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>User Name</th>
            <th>E-Mail</th>
            <th>Predictions Completed</th>
            <th>Date Achieved</th>
          </tr>
        </thead>
        <tbody>
          {ticketsApi.data.map((item, index) => <tr key={`row-key-${index}`}>
            <td>{item.id}</td>
            <td>{item.username}</td>
            <td>{item.email}</td>
            <td>{item.predictionsCompleted}</td>
            <td>{NewYorkTz.format(new Date(item.openedAt * 1000)).monthNameDateTime() }</td>
          </tr>
          )}
        </tbody>
      </table>
      }


      {ticketsApi.error &&
        <div className="alert alert-danger p-5 d-flex justify-content-center">API Error occurred. {ticketsApi.error}</div>
      }

    </div>
  )
}
