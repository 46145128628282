import React, {useEffect, useState} from "react";
import {AnalysisInputParams, AutoTradeSecurityTypeEnum, OptionType,} from "predictagram-lib";
import {Form, Formik} from "formik";
import {FieldWithError} from "components/common";
import {Spinner} from "components/common/Spinner";
import {StrategyProfitModel as model} from "models/strategy-profit.model";
import {AnalysisForm} from "../analysis/strategy-profit/AnalysisForm";
import {IEquityTradingFormParameters, IOptionTradingFormParameters} from "../../../models/trade-setup.model";
import {editSignalAlertLink} from "../analysis/alerts/List";

export enum SubmitModeEnum {
  UPDATE = 1,
  SAVE_AS = 2,
  NEW = 3,
}

export type TradeSetupFilterOptions = AnalysisInputParams & {
  // symbolName: string,
  // alertName: string,
  // alertEmails: string[],
  // alertSendCloseSignals: boolean,
  last30dScore: number,
  tradeSetupName: string,
  signalAlertId: number,
  tradeSetupSignalAlertId: number|null,
  tradeSecurityTypeId: AutoTradeSecurityTypeEnum,
  quantityPerTrade: number,
  maxActiveTrades: number,
  maxTradesPerDay: number,
};


export const TradeSetupForm: React.FunctionComponent<{
  initialValues: Partial<TradeSetupFilterOptions>,
  onClick: (search: any, submitMode: SubmitModeEnum) => Promise<void>,
  isEditing: boolean,
  tradeSecurityType: AutoTradeSecurityTypeEnum,
  onOpenTriggerDirection?: (direction: "up" | "dn") => void,
  onOpenSignalDirection?: (direction: "up" | "dn") => void,
  optionsFormValues: Partial<IOptionTradingFormParameters|IEquityTradingFormParameters>,
}> = ({ initialValues, onClick, isEditing,tradeSecurityType, onOpenTriggerDirection, onOpenSignalDirection, optionsFormValues }) => {
  const [submitMode, setSubmitMode] = useState<SubmitModeEnum>(SubmitModeEnum.NEW);

  useEffect(() => {
    setSubmitMode(initialValues !== undefined && initialValues !== null ? SubmitModeEnum.UPDATE : SubmitModeEnum.NEW)
  }, [initialValues])

  const initVals = Object.assign(model.defVals, initialValues) as TradeSetupFilterOptions;

  const onSubmit = async (opts: TradeSetupFilterOptions, actions: any) => {
    await onClick(opts, submitMode);
    actions.setSubmitting(false);
  }

  return (
    <div>
      <div className="page-title mb-3">Trade Setup</div>

      <Formik initialValues={initVals} enableReinitialize onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          return <Form>

            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-start align-items-end gap-2">


                <div className="d-flex gap-3 align-items-center">
                  <FieldWithError size={3} errors={errors} touched={touched} fieldName="signalAlertId" label="Alert Id" />
                  <FieldWithError size={55} errors={errors} touched={touched} fieldName="tradeSetupName" label={`Trade Setup Name` /*+ (initVals.tradeSetupSignalAlertId? ` (alert ${initVals.tradeSetupSignalAlertId})`:'')*/} />
                </div>

                <div className="d-flex gap-3">
                  <FieldWithError size={4} fieldName="maxActiveTrades" errors={errors} touched={touched} label="Max Open Trades" />
                  <FieldWithError size={4} fieldName="quantityPerTrade" errors={errors} touched={touched} label="Quantity Per Trade" />
                  <FieldWithError size={4} fieldName="maxTradesPerDay" errors={errors} touched={touched} label="Max Trades Per Day" />
                </div>

                <div className="d-flex gap-1 flex-column rounded border p-2 align-items-center">
                  <div className="fw-bold">Template</div>
                  <div className="d-flex gap-2">
                    <span>{optionsFormValues.ibkr?.side}</span>
                    {tradeSecurityType === AutoTradeSecurityTypeEnum.OPTION &&
                     <span className={`text-${(optionsFormValues as IOptionTradingFormParameters)?.optionType === OptionType.CALL ? 'green' : 'red'} fw-bold`}>{(optionsFormValues as IOptionTradingFormParameters)?.optionType?.toUpperCase()}</span>
                    }
                    {tradeSecurityType === AutoTradeSecurityTypeEnum.EQUITY &&
                     <span>EQUITY</span>
                    }
                    <span>{optionsFormValues.ibkr?.orderType}</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  {isSubmitting && <Spinner minHeight={50} />}
                  {isEditing ?
                    <div className="d-flex gap-2">
                      <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.UPDATE)} className="btn btn-primary p-2" disabled={isSubmitting}>Update</button>
                      <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.SAVE_AS)} className="btn btn-primary p-2" disabled={isSubmitting}>Save As New</button>
                    </div>
                    :
                    <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.NEW)} className="btn btn-primary p-2" disabled={isSubmitting}>Save New</button>
                  }
                </div>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
};
