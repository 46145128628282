import React from 'react'
import { formatUSCurrency } from './Trades'
import { PointUpDownOrders } from './ChartOverlay'
import { NewYorkDate } from 'components/common/NewYorkDate'
import {Hover} from "../common/Hover";

export const OrderTable:React.FunctionComponent<{orders: PointUpDownOrders[] | undefined}> = ({orders}) => {
  return (
    <table className="table table-hover text-13" style={{ maxWidth: "800px" }}>
    <thead><tr>
      <th>Action</th>
      <th>Side</th>
      <th>Option Name</th>
      <th>Order Date <Hover onHover="IBKR Order start time">?</Hover></th>
      <th className="text-end">Order Price</th>
      <th className="text-end">Stock Price <Hover onHover="the price at time when the analysis detected an open/close signal using live price">?</Hover></th>
    </tr></thead>
    <tbody>
      {orders && orders.length > 0 ? orders.map((o, i) => <tr key={`oid-${i}`}>
        <td>{o.action}</td>
        <td>{o.side}</td>
        <td>{o.optionName}</td>
        <td><NewYorkDate dateInMs={o.actualOrderDate * 1000} /></td>
        <td className="text-end">{formatUSCurrency(o.contractPrice)}</td>
        <td className="text-end">{formatUSCurrency(o.y)}</td>
      </tr>) : <tr>
        <td colSpan={6}>No Data Found</td>
      </tr>}
    </tbody>
  </table>

  )
}
