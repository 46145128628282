import React from 'react';
import { Outlet } from 'react-router-dom';
import {adminAccountService, adminAuthenticationService} from "../../../services";
import {AccountProvider} from "../../common/AccountProvider";

export const AdminNoLayout: React.FunctionComponent = () => {
  return (
    <AccountProvider accountService={adminAccountService} authService={adminAuthenticationService} disableNotification={true}>

    <div className="container-fluid g-0 bg-white">
      <Outlet />
    </div>
    </AccountProvider>
  );
}
