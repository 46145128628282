import React from 'react'
import { FieldWithError } from 'components/common'
import { Form, Formik } from 'formik'
import {AutoTradeSecurityTypeEnum, IBKROrderType, IBKRSide, OptionType} from 'predictagram-lib'
import {IOptionTradingFormParameters, TradeSetupModel as tradeModel} from 'models/trade-setup.model'
import { DropDown } from 'components/common/form/DropDown';


interface IProps { 
  initialValues: any, 
  onSubmit: (data: any) => void,
  onCancel: ()=> void,
}
export const TradingFormOptions: React.FunctionComponent<IProps> = ({ initialValues, onSubmit, onCancel }) => {

  const submitHandler = (data: IOptionTradingFormParameters, actions: any) => {
    data.maxExpirationDays = parseInt(data.maxExpirationDays as any, 10);
    data.minExpirationDays = parseInt(data.minExpirationDays as any, 10);
    data.strikePriceOffset = parseInt(data.strikePriceOffset as any, 10);
    onSubmit(data);
  }

  return (
    <div className="p-3">
      <div className="fw-bold my-3 text-17">Options Trade Template</div>
      <Formik onSubmit={submitHandler} initialValues={initialValues} enableReinitialize>
        {({ values, touched, errors }) => {
          return <Form>

            <div className="d-flex flex-column gap-2">

              <div className="d-flex flex-column gap-1 my-2">
                <div className="fw-bold">Expiration Date</div>
                <div className="fst-italic">(Days specified below are Market Days, not Calendar Days)</div>
                <div className="d-flex gap-2">
                  <DropDown enumMap={tradeModel.daysAway}  errors={errors} touched={touched} label="Min Days Away" name='minExpirationDays' /><br />
                  <DropDown enumMap={tradeModel.daysAway} errors={errors} touched={touched} label="Max Days Away" name='maxExpirationDays' /><br />
                </div>
              </div>

              <div className="align-self-start">
              <FieldWithError label='Strike Price Offset' placeholder='amount to offset ATM' labelClassName='text-16' fieldName='strikePriceOffset' errors={errors} touched={touched} />
              </div>

              <div className="d-flex gap-2 justify-content-between">
                <DropDown enumMap={tradeModel.ibkrSide} errors={errors} touched={touched} label="Transaction Type" name='ibkr[side]' /><br />
                <DropDown enumMap={tradeModel.optionType} errors={errors} touched={touched} label="Option Type" name='optionType' /><br />
                <DropDown enumMap={tradeModel.orderType} errors={errors} touched={touched} label="Order Type" name='ibkr[orderType]' /><br />
              </div>

            </div>
            <div className="d-flex gap-3 my-3">
              <button className="btn btn-primary" type="submit">OK</button>
            </div>

          </Form>
        }}
      </Formik>
    </div>
  )
}
