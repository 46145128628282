import { getAnalysisInputParams } from "components/admin/common/Criteria";
import { IApiResponse, useApi } from "./useApi";
import {Analysis, IAutoTradeSetup, ISignalAlert, StockSignalsEnum} from "predictagram-lib";
import {adminAutoTradeSetupService, adminSignalAlertApiService} from "services/AdminApiService";
import { useEffect, useState } from "react";
import { TradeSetupFilterOptions } from "components/admin/autotrade/TradeSetupForm";



type Signals = {
  openSignals: StockSignalsEnum[];
  closeSignals: StockSignalsEnum[];
}

type IAutoTradeSetupEx = IAutoTradeSetup&{signalAlert:ISignalAlert};

export interface IAdminAutoTradeSetupSingle {
  api: IApiResponse<IAutoTradeSetupEx, number>,
  signals: Signals | undefined
}

export function useAdminAutoTradeSetupSingle (id: number): IAdminAutoTradeSetupSingle {

  const [data, setData] = useState< IAutoTradeSetupEx | undefined>(undefined);
  const [signals, setSignals] = useState<Signals | undefined>(undefined);

  useEffect(()=>{
    if (data) {
      const analysisInputParams = getAnalysisInputParams(data.signalAlert.data as TradeSetupFilterOptions);
      const openSignals = analysisInputParams?.openSignalSetup?.signals.map(s=>s.type) || [];
      const closeSignals = analysisInputParams?.closeSignalSetup?.signals.map(s=>s.type) || [];
      setSignals({
        openSignals,
        closeSignals
      });
    } else {
      setSignals(undefined);
    }
  },[data])

  const load = async (id: number) => {
    const data = await adminAutoTradeSetupService.getById(id) as IAutoTradeSetupEx;
    if (data) {
      data.signalAlert = await adminSignalAlertApiService.getById(data.signalAlertId) as ISignalAlert;
    }
    setData(data);
    return data;
  }


  return {
    api:  useApi<IAutoTradeSetupEx, number>(load, id),
    signals
  }
}

