import React, { useEffect, useState } from "react";
import { ListContainer } from "components/common";
import { FormButtons } from "_constants";
import { IDataField } from "interfaces";

import { adminSignalAlertApiService } from "services/AdminApiService";
import { useNavigate } from "react-router-dom";
import { UrlHelper } from "_utils/UrlHelper";
import { Checkbox } from "../../../common/form/Checkbox";
import { TradeModel } from "models/trade.model";
import { useProcessingModal } from "_hooks/useProcessingModal";
import { LinkAdminTradeSetup } from "components/admin/autotrade/LinkAdminTradeSetup";
import { Clock } from "react-bootstrap-icons";
import {DateHelper, ISignalAlert, SignalAlertStatusEnum} from "predictagram-lib";
import { StatusDropDown } from "./StatusDropDown";
import {SignalAlertHelper} from "../../../../_utils/signal-alert.helper";



export const cacheStats = (row: any, simple?: boolean) => {
  const renderHist = (d: any, pref: any) => {
    if (!d) {
      return 'N/A';
    }
    return `${pref}: ${(d.score.overall * 100).toFixed(2)}% trades ${d.trades.total}`;
  }
  return <div>
    <div>{renderHist(row.cache.last7, 'Last 7')}</div>
    <div>{renderHist(row.cache.last14, 'Last 14')}</div>
    <div>{renderHist(row.cache.last30, 'Last 30')}</div>
    <div>{renderHist(row.cache.last90, 'Last 90')}</div>
    {!simple && <>
      <div>{renderHist(row.cache.last150, 'Last 150')}</div>
    </>}
  </div>
}


export const editSignalAlertLink = (row: {id:number}, label="Edit") => {
  return <button className="btn btn-secondary" type="button" onClick={() => {
    const url = `/admin/analysis/strategy-profit?id=${row.id}`;
    window.open(url, '_blank');
  }}>
    {label}
  </button>
}

export const AdminSignalAlertList: React.FunctionComponent = () => {

  const navigate = useNavigate();

  // const [showPerfCol, setShowPerfCol] = useState<boolean>(true);
  const [showExtraCols, setShowExtraCols] = useState<boolean>(false);

  const [fieldsList, setFieldList] = useState<any>([]);

  const processingModal = useProcessingModal();



  const getName = (v: any) =>
    <div className="d-flex gap-2">
      {v.name}
      <div role="button" className="text-primary" onClick={() => window.open(UrlHelper.getAdminCumeHistory(v.id), '_blank')} title="Cumulative Chart History"><Clock /></div>
    </div>


  const handleEditStatus = async (statusId: number, signalAlert: ISignalAlert) => {
    const payload = { statusId } as any;
    const oldValue = signalAlert.statusId;
    try {
      await adminSignalAlertApiService.update(payload, signalAlert.id);
      signalAlert.statusId = statusId;
    } catch (error: any) {
      signalAlert.statusId = oldValue;
      console.error(error);
      alert('Failed to update status. See error console.');
    }
  }

  const signalStatus = (signalAlert: ISignalAlert) => {
    return (
      <StatusDropDown setValue={(statusId: SignalAlertStatusEnum) => handleEditStatus(statusId, signalAlert)} value={signalAlert.statusId} />
    );
  }

  useEffect(() => {
    const fields: Map<string, [string, Function | undefined]> = new Map([
      ['id', ['ID', undefined]],
      ['parentId', ['Parent ID', undefined]],
      ['name', ['Name', getName]],
      ['notes', ['Notes', undefined]],
      ['emails', ['Emails', (v: any) => v.emails.join(', ')]],
      ['symbol', ['Symbol', (v: any) => v.data.symbolName]],
      ['categories', ['Category', (v: any) => v.categories.join(' ')]],
      ['createdAt', ['Created', (v:any)=>DateHelper.dateTimeFormatUs(v.createdAt)]],
      ['todayScore', ['Today Score', (v:any)=>SignalAlertHelper.getAlertScoreToString(v.todayScore)]],
      ['performance', ['Performance', (v:any)=>{
        return (<>
            <div style={{minWidth:'200px'}}>{cacheStats(v)}</div>
          </>
        )
      }]],
      ['statusId', ['Status', signalStatus]],
      ['edit', ['Edit', editSignalAlertLink]],
    ]);
    if (!showExtraCols) {
      ['emails','symbol','categories'].forEach(f=>fields.delete(f));
      //fields.delete('performance');
    }
    setFieldList(fields);
  }, [showExtraCols]);

  const emptyValues = {} 

  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'name', title: 'Name', editable: true },
  ];

  const onSelectedRows = async (ids: string[]) => {
    const created: [number, string, string][] = [];
    let errorcount = 0;

    processingModal.setProcessing(true);
    processingModal.setShow(true);

    await Promise.all(ids.map(async (id) => {
      try {
        const results = await TradeModel.createTradeSetupFromSignalAlert(parseInt(id));
        results.map(result=>created.push([result.id, TradeModel.getSetupSecurityName(result) + ' ' + result.name, 'OK']));
      } catch (error: any) {
        console.error({ error });
        errorcount++;
      }
    }));

    processingModal.setContent(<div className="d-flex flex-column gap-2 p-3 text-12">
      {created.length > 0 ?
        <div><div className="fw-bold">Created:</div>
          <table><thead><tr><th>Trade Setup ID</th><th>Name</th></tr></thead>
            <tbody>
              {created.map(([id, name]) => <tr>
                <td>{id}</td>
                <td>{name}</td>
                <td><LinkAdminTradeSetup tradeSetupId={id} /></td>
              </tr>)}
            </tbody></table>
        </div>
        :
        <div>None were created</div>
      }

      <div>
        {errorcount} errors occurred.
      </div>

      <button type="button" className="btn btn-secondary text-12" onClick={() => processingModal.setShow(false)}>Close</button>
    </div>);
    processingModal.setProcessing(false);
  }

  return (
    <>
      {processingModal.render()}
      <div className="d-flex justify-content-between align-items-end">
        <div className="page-title">Signal Alerts</div>
        {/*<Checkbox label="Show Profit" value={showPerfCol} onChange={() => setShowPerfCol(!showPerfCol)} />*/}
        <Checkbox label="Extra Columns" value={showExtraCols} onChange={() => setShowExtraCols(!showExtraCols)} />
        <button className="btn btn-primary mt-2" onClick={() => navigate(UrlHelper.getAdminSignalAlertAdd())}>Create New Signal Alert</button>
      </div>
      <ListContainer
        apiService={adminSignalAlertApiService}
        emptyValues={emptyValues}
        fieldList={fieldsList}
        buttons={[FormButtons.DELETE]}
        editableFields={editableFields}
        hasMultiSelect={true}
        multiSelectLabel='Create Trade Setups'
        onSelectedRows={onSelectedRows}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <></>
          );
        }}
      </ListContainer>


    </>

  );
};
