import React from 'react'
import { FieldWithError } from 'components/common'
import { Form, Formik } from 'formik'
import { AutoTradeSecurityTypeEnum, IBKROrderType, IBKRSide } from 'predictagram-lib'
import { TradeSetupModel as tradeModel } from 'models/trade-setup.model'
import { DropDown } from 'components/common/form/DropDown';

interface IProps { 
  initialValues: any, 
  onSubmit: (data: any) => void,
  onCancel: ()=> void,
}
export const TradingFormEquity: React.FunctionComponent<IProps> = ({ initialValues, onSubmit, onCancel }) => {

  const submitHandler = (data: any, actions: any) => {
    onSubmit(data);
  }

  return (
    <div className="p-3">
      <div className="fw-bold my-3 text-17">Shares Trade Template</div>
      <Formik onSubmit={submitHandler} initialValues={initialValues} enableReinitialize>
        {({ values, touched, errors }) => {
          return <Form>

            <div className="d-flex flex-column gap-2">

              <div className="d-flex gap-2 justify-content-between">
                <DropDown enumMap={tradeModel.ibkrSide} errors={errors} touched={touched} label="Transaction Type" name='ibkr[side]' /><br />
                <DropDown enumMap={tradeModel.orderType} errors={errors} touched={touched} label="Order Type" name='ibkr[orderType]' /><br />
              </div>

            </div>
            <div className="d-flex gap-3 my-3">
              <button className="btn btn-primary" type="submit">OK</button>
            </div>

          </Form>
        }}
      </Formik>
    </div>
  )
}
