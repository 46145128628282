import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ChartHelper } from './ChartHelper';
import { SingleChart } from './SingleChart';
import * as moment from 'moment-timezone';
import {TodayChart} from './TodayChart';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form';
import { TimeZone } from '_constants/TimeZone';
import {StockHelper} from "../../../../_utils/stock.helper";
import {DateEx, DateHelper, IQuoteFull, IStockDay} from "predictagram-lib";
import {IStockData, IStockQuotePartial} from "./ICandlestickLineItem";
import {adminPredictionApiService} from "../../../../services/AdminApiService";
import {ChartFilters} from "../../../public/Stock/ChartFilters";


function dragElement(elmnt: HTMLDivElement) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  elmnt.onmousedown = dragMouseDown;
  elmnt.style.cursor = 'grabbing';

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}


export const getStockData = async(symbol:string, currentDate: IStockDay)=>{
  const p = StockHelper.workingHours(new Date(currentDate.prevDay().openAt()*1000));
  const c = StockHelper.workingHours(new Date(currentDate.openAt()*1000));
  const priorDay: ReadonlyArray<IQuoteFull> = await adminPredictionApiService.getBarsData(symbol, p.start, p.end, true);
  const _today: ReadonlyArray<IQuoteFull> = await adminPredictionApiService.getBarsData(symbol, c.start, c.end, true);

  const today = _today.filter(ChartFilters.tradingHours);
  // console.debug({today});
  const priorHour = priorDay.filter(ChartFilters.lastHour);

  // need to fill mising minutes with empty data
  const startMinute = today?.[0]?.t ? today[0].t + 60 : c.start.getTimeSec();
  const lastMinute = c.start.getTimeSec() + 3600*6.5 - 60;

  const todayFull = [...today] as IStockQuotePartial[];

  for (let i = startMinute;i<=lastMinute;i+=60) {
    todayFull.push({
      c: null,
      l: null,
      h: null,
      o: null,
      v: null,
      t: i,
    })
  }
  return [...priorHour, ...todayFull] as IStockQuotePartial[]
  //return {data: [...priorHour, ...todayFull] as IStockQuotePartial[], priorHour: priorHour, today: today};
}


export const MultiCompare: React.FunctionComponent = () => {

  const todayStockDay = StockHelper.findTradingDay(new DateEx(), -1, true);
  const currentStockDay = todayStockDay.isPremarket() ? todayStockDay.prevDay() : todayStockDay;
  // const currentStockDay = StockHelper.getIfIsTradingDay(currentDate.getTimeSec()) as IStockDay;
  // console.log({currentDate});
  // const previousDate = currentStockDayStockHelper.findPreviousTradingDay(currentDate); // ChartHelper.findPreviousTradingDay(currentDate);
  const [scaled, setScaled] = useState<boolean>(false);
  const [candidatesOnly, setCandidatesOnly] = useState<boolean>(false);
  const [isCandleStick, setIsCandleStick] = useState<boolean>(true);

  const [stockData, setStockData] = useState<IStockData[]>([]);

  const [currentDay, setCurrentDay] = useState<IStockDay>(currentStockDay);
  const [startDate, setStartDate] = useState<DateEx>(new DateEx(currentStockDay.prevDay().openAt()*1000));
  const [endDate, setEndDate] = useState<DateEx>(new DateEx(currentStockDay.openAt()*1000));
  const [candidates, setCandidates] = useState<IStockDay[]>([]);
  // const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // console.debug({currentDate,previousDate})

  const [filterStartDate, setFilterStartDate] = useState<DateEx>(new DateEx(moment.tz(TimeZone.AMERICA_NEW_YORK).subtract(1, 'day').format()));
  const [filterEndDate, setFilterEndDate] = useState<DateEx>(new DateEx(moment.tz(TimeZone.AMERICA_NEW_YORK).subtract(1, 'day').format()));
  const [filterToday, setFilterToday] = useState<DateEx>(new DateEx(currentStockDay.openAt()*1000));
  //
  // const [loading, setLoading] = useState<string[]>([]);

  const [stockSymbol, setStockSymbol] = useState<string>('TNA');
  const [filterSymbol, setFilterSymbol] = useState<string>('TNA');

  const onToggleCandidate = (date: IStockDay, isOn: boolean) => {
    if (isOn) {
      setCandidates([...candidates, date]);
    }

    if (!isOn) {
      setCandidates(candidates.filter((item) => { return item.openAt() !== date.openAt() }));
    }
  }

  const ref = useRef<HTMLDivElement>(null);
  //
  // const loadingRef = useRef<IStockDay[]>([]);

  useEffect(() => {
    if (ref.current) {
      dragElement(ref.current);
    }
  }, [])

  const loadData = async (stockSymbol: string, startDate: DateEx, endDate: DateEx, currentDay: IStockDay) => {

    const allDays:IStockData[] = [];

    let maxDiff = 0;

    const daysRange = [currentDay, ...ChartHelper.tradingDaysRange(startDate, endDate)];

    for (const d of daysRange) {
      const getDayData = async()=>{
        let min:number = null as any;
        let max:number = null as any;
        const data = await getStockData(stockSymbol, d);
        data.forEach(q=>{
          if (q.l!==null) {
            min = Math.min(min||q.l, q.l);
          }
          if (q.h!==null) {
            max = Math.max(max||q.h, q.h);
          }
        });

        return {
          data: data,
          minY: min,
          maxY: max,
          date: d,
          get: getDayData,
        } as IStockData;
      }
      const dayData = await getDayData();
      allDays.push(dayData);
      maxDiff = Math.max(maxDiff, dayData.maxY-dayData.minY);
    }


    const fullScaleHalf = maxDiff*1 / 2;

    const results: IStockData[] = [];
    // need to find biggest high/low difference to calc scale for all charts
    for (const day of allDays) {
      const middle = (day.maxY-day.minY)/2 + day.minY;
      results.push({
        date: day.date,
        data: day.data,
        minY: middle-fullScaleHalf,
        maxY: middle+fullScaleHalf,
        get: day.get,
      })
    }
    setStockData(results);
  }

  useEffect(()=>{
    (async() => {
      try {
        setIsLoading(true);
        await loadData(stockSymbol, startDate, endDate, currentDay);
      } finally {
        setIsLoading(false);
      }
    })();

  }, [stockSymbol, startDate, endDate, currentDay]);

  const onScroll = (e: any) => {
    //setRefresh(refresh + 1);
  }

  // const onLoad = (name: IStockDay) => {
  //   // console.log(`onloaded ${name}`, loadingRef.current);
  //   const i = loadingRef.current.findIndex(d=>d.openAt()===name.openAt()); // indexOf(name);
  //   if (i >= 0) {
  //     loadingRef.current.splice(i, 1);
  //   }
  //   setIsLoading(loadingRef.current.length > 0);
  // }

  const render = (date: IStockDay, i: number) => {
    const currentDate = date; // DateHelper.formatDateUs(date.openAt()); // new Date(nyDate.format());
    // console.debug({currentDate, date});
    // const previousDate = date.prevDay(); // ChartHelper.findPreviousTradingDay(new Date(nyDate.format()));
    const saved = candidates.findIndex(d=>d.openAt()===date.openAt())>=0; // indexOf(nyDate.format('YYYY-MM-DD')) >= 0;
    // console.debug('render check');
    // if (nyDate.format('YYYYMMDD') === moment.tz(TimeZone.AMERICA_NEW_YORK).format('YYYYMMDD')) {
    if (date.openAt()===StockHelper.getIfIsTradingDay(new DateEx().getTimeSec())?.openAt()) {
      return <></>
    }
    // console.log('rendering ', date);
    // console.debug({currentDate:currentDate.openAt(), previousDate:previousDate.openAt(), 'm':'singleChart'});

    const data = stockData.find((d)=>d.date.openAt()===date.openAt());
    if (!data) {
      return;
    }
    return <div className="mb-5" style={{ width: '1000px' }} key={`chartkey-${i}`}>
      <pre>{JSON.stringify(date, null, 2)}</pre>
      <SingleChart
        lazyLoad={false}
        // refresh={refresh}
        symbol={stockSymbol}
        saved={saved}
        // previousDate={previousDate}
        // fullXAxis={true}
        scaled={scaled}
        onToggle={onToggleCandidate}
        // onLoad={onLoad}
        isCandleStick={isCandleStick}
        stockData={data}
      />
    </div>
  }


  const onSymbolChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterSymbol(e.target.value);
  }

  const onClickUpdate = () => {
    // setIsLoading(true);

    setStartDate(filterStartDate);
    setEndDate(filterEndDate);
    setStockSymbol(filterSymbol);
    setCurrentDay(StockHelper.findTradingDay(filterToday, -1, true));
    // set the loading array
    // const days = ChartHelper.tradingDays(moment.tz(filterStartDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD'), moment.tz(filterEndDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD'));
    // const days = ChartHelper.tradingDaysRange(filterStartDate, filterEndDate);
    // loadingRef.current = days;
    // if (days.length === 0) {
    //   setIsLoading(false);
    // }
  }

  const todayChart = () => {
    const data = stockData.find(d=>d.date.openAt()==currentDay.openAt());
    if (!data) {
      return;
    }
    return (
        <TodayChart symbol={stockSymbol} isCandleStick={isCandleStick} scaled={scaled} stockData={data}/>
    )
  }

  return (
    <div className="" style={{ backgroundColor: "#ffffff" }}>
      <div className="text-13 d-flex justify-content-start align-items-center multi-stock-form " style={{ zIndex: 10000 }}>
        <div>
          <button className="btn btn-primary my-3" onClick={() => setScaled(!scaled)}>{scaled ? 'Show Price' : 'Show % Change'}</button>
        </div>

        <div className="ms-3">
          <Form.Check
            type="switch"
            id="show-all"
            label="Candidates Only"
            onClick={() => { setCandidatesOnly(!candidatesOnly) }}
          />
          <Form.Check
            type="switch"
            id="show-all"
            label="CandleStick"
            defaultChecked={isCandleStick}
            onChange={() => { setIsCandleStick(!isCandleStick) }}
          />
        </div>

        <div className="multi-stock-form-symbol mx-2">
          <div>Symbol</div>
          <input type="text" value={filterSymbol} onChange={onSymbolChange} />
        </div>

        <div className="multi-stock-form-date mx-2" style={{ zIndex: 10000 }}>
          Start Date
          <ReactDatePicker selected={filterStartDate} onChange={(date: Date) => setFilterStartDate(new DateEx(date))} />
        </div>

        <div className="multi-stock-form-date mx-2" style={{ zIndex: 10000 }}>
          End Date
          <ReactDatePicker selected={filterEndDate} onChange={(date: Date) => setFilterEndDate(new DateEx(date))} />
        </div>

        <div className="multi-stock-form-date mx-2" style={{ zIndex: 10000 }}>
          Date Overlay
          <ReactDatePicker selected={filterToday} onChange={(date: Date) => setFilterToday(new DateEx(date))} />
        </div>


        <div className="align-self-center mx-2">
          <button className="btn btn-primary" onClick={() => onClickUpdate()} disabled={isLoading}>{isLoading ? <>Loading...</> : <>Update</>}</button>
        </div>

        {isLoading &&
          <div className="align-self-center ms-2">
            <div className="spinner-border " role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }

      </div>

      {/* <div onScroll={onScroll} style={{ height: '735px', overflowY: "scroll", width: '95%', minWidth: '1400px' }}> */}
      <div>
        <div ref={ref} style={{ position: "fixed", top: '111px', width: '1000px', zIndex: 1000 }} className="border">
          {todayChart()}
        </div>



        {candidates.length > 0 && candidatesOnly &&
          <div className="list-charts" style={{ marginTop: '5px', marginBottom: '350px' }}>
            {candidates.map(render)}
          </div>
        }

        {(candidates.length === 0 || !candidatesOnly) &&
          <div className="list-charts" style={{
            marginTop: '5px',
            // marginBottom: '350px' 
          }}>
            {/*{ChartHelper.tradingDays(moment.tz(startDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD'), moment.tz(endDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')).map(render)}*/}
            {ChartHelper.tradingDaysRange(startDate, endDate).map(render)}
          </div>
        }
      </div>

    </div>
  );
}
