import React from 'react';
import "./App.scss";
import 'react-calendar/dist/Calendar.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PublicLayout } from 'components/public/layout/PublicLayout';
import { NotFoundPage } from 'components/NotFoundPage';
import { ListingsPageDynamic } from 'components/public/ListingsPage/ListingsPageDynamic';
import { FloatingMakeAPredictionButton } from 'components/common/FloatingMakeAPredictionButton';

import { WelcomePage } from 'components/public/WelcomePage';
import { WelcomeLayout } from 'components/public/layout/WelcomeLayout';

import { AdminRoutes } from 'routes/AdminRoutes';
import { UserRoutes } from 'routes/UserRoutes';
import { PublicRoutes } from 'routes/PublicRoutes';
import { LandingRoutes } from 'routes/LandingRoutes';
import { TradegramRoutes } from 'routes/TradegramRoutes';
import { SurveyTermsPage } from 'components/common/SurveyTermsPage';
import { BlackLayout } from 'components/public/layout/BlackLayout';
import { CrowdFundingPage } from 'components/user/crowdfunding/CrowdFundingPage';
import { WelcomeXPage } from 'components/public/WelcomeXPage';
import { DiscountPromoPage } from 'components/marketing/discount-promo/DiscountPromoPage';
import { DiscountPromoSignupPage } from 'components/marketing/discount-promo/DiscountPromoSignupPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>

      <Route path="/welcomex" element={<WelcomeLayout />}>
          <Route index element={<WelcomeXPage />} />
        </Route>

        <Route path="/welcome" element={<WelcomeLayout />}>
          <Route index element={<WelcomePage />} />
        </Route>

        <Route path="/survey-terms-of-service" element={<BlackLayout />} >
          <Route index element={<SurveyTermsPage />} />
        </Route>

        <Route path="/crowd-funding" element={<BlackLayout />} >
          <Route index element={<CrowdFundingPage />} />
        </Route>


        <Route path="/pro-signals-package" element={<BlackLayout />}>
          <Route index element={<DiscountPromoPage />} />
          <Route path="signup" element={<DiscountPromoSignupPage />} />
        </Route>

        <Route path="/landing/*" element={<LandingRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/user/*" element={<UserRoutes />} />
        <Route path='/tradegram/*' element={<TradegramRoutes />} />

        <Route path="/lp" element={<PublicLayout hasDisclaimer={true} />}>
          <Route index element={<><ListingsPageDynamic /><FloatingMakeAPredictionButton /></>} />
        </Route>

        <Route path="/*" element={<PublicRoutes />} />

        <Route path='/not-found' element={<NotFoundPage />} />

        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
