import {Analysis, LinePoints, SignalCondition, SignalSetup, StockDirectionType, StockTriggerType} from "../interface";
import {MathEx} from "../utils";

export class AnalysisHelper {
    public static emptyLine() {
        return { x: [] as number[], y: [] as number[]} as LinePoints;
    }

    public static directionString(data:Analysis.InputParams) {
        const dir = this.direction(data);
        const dirStr = dir===StockDirectionType.UP? 'UP' : (dir===StockDirectionType.DOWN? 'DOWN' : 'BOTH');
        return dirStr;
    }

    public static direction(alert:Analysis.InputParams): StockDirectionType|null {
        const getDir = (types?:StockDirectionType[])=>{
            const dir = types?.join(',')||'';
            return dir==='-1' ? StockDirectionType.DOWN : (
                    dir==='1' ? StockDirectionType.UP : null
            );
        }
        if (alert.openTriggerDirectionTypes?.length) {
            return getDir(alert.openTriggerDirectionTypes);
        } else {
            const sgs = alert.openSignalSetup?.signals;
            if (sgs && sgs.length > 0) {
                return getDir(sgs[0].directionTypes);
            }
        }
        return null;
    }


    public static analysisFingerPrint(data:Analysis.InputParams, getSymbolName:(id:number)=>string, isFull:boolean) {
        // Symbol, Direction, 1st Signal and Direction, 2nd Signal and Direction, 3rd Signal and Dir, (etc), any Includes or Excludes, Time Range, Exit Signals and Directions, TP and SL

        const fgParts:string[] = [
            getSymbolName(data.stockSymbolId),
            AnalysisHelper.directionString(data),
        ];
        const rateToStr = (rateIn:number, withSign:boolean) => {
            let rateStr = Math.abs(MathEx.round(rateIn * 100, 3)).toString();
            if (rateStr.startsWith('0.')) {
                rateStr = rateStr.substring(1);
            }
            if (withSign) {
                rateStr = (rateIn >= 0 ? '+' : '-') + rateStr;
            }
            return rateStr + '%';
        }

        const toMinutes = (secs:number) => {
            if (!secs) {
                return '0m';
            }
            const min = MathEx.round(secs/60, 0);
            return `${min}m`;

        }

        if (data.windowTimeSecs && isFull) {
            fgParts.push(`window:${toMinutes(data.windowTimeSecs)}`);
        }
        if (data.windowMinElements && isFull) {
            fgParts.push(`min:${data.windowMinElements}`);
        }
        if (data.windowMinElements  && isFull) {
            fgParts.push(`max:${data.windowMinElements}`);
        }
        if (data.prevCurDayPriceGap?.diffMinRate && isFull) {
            fgParts.push(`gap:${data.prevCurDayPriceGap.direction===StockDirectionType.DOWN?'-':'+'}${rateToStr(data.prevCurDayPriceGap.diffMinRate, false)}`);
        }

        const signalsFg = (sgSetup?:SignalSetup)=> {
            const sgsListOrig:string[] = [];
            const sgs = sgSetup?.signals || [];
            const sgsLen = sgs.length;
            for (const sg of sgs) {
                const sgName = sg.type.replace('signals', '');
                const sgOptions:string[] = [
                    sgName
                ];
                if (sg.oppositeDirection) {
                    sgOptions.push('oppos');
                }
                if (sg.exclude) {
                    sgOptions.push('excl');
                }

                // let sgFg = `${sgName}${sg.oppositeDirection ? ',Oppos' : ''}${sg.exclude ? ',Excl' : ''}`

                if (isFull) {
                    if (sg.repeatSecs) {
                        sgOptions.push('rep:'+toMinutes(sg.repeatSecs));
                    }
                    if (sg.cancellationSecs) {
                        sgOptions.push('canc:'+toMinutes(sg.cancellationSecs));
                    }
                }
                if (isFull && sg?.options) {
                    for (const [k,v] of Object.entries(sg.options)) {
                        const vFinal = k.endsWith('Rate') ? rateToStr(v, false) : v;
                        sgOptions.push(`${k.substring(0, 6)}:${vFinal}`)
                    }
                }

                sgsListOrig.push(sgOptions.join(','));

            }
            const isAnd = sgs?.[0]?.condition===SignalCondition.AND;
            const isStrict = sgSetup?.strictSequence||false;

            const sgsListSorted:string[] = [];
            // need to sort starting from second signal to be able to compare. NotStrict means 2,3==3,2 for analysis
            if (sgsLen>2 && !isStrict) {
                sgsListSorted.push(sgsListOrig[0]);
                const subList = sgsListOrig.slice(1).sort();
                sgsListSorted.push(...subList);
            } else {
                sgsListSorted.push(...sgsListOrig);
            }

            let sgsListStr = sgsListSorted.join(isAnd?'&':'|');
            if (isFull && sgSetup?.intersectionSecs) {
                sgsListStr = `intersec:${toMinutes(sgSetup.intersectionSecs)},${sgsListStr}`;
            }
            return isStrict ? `[${sgsListStr}]` : `(${sgsListStr})`;

        }
        const sgsList:[string,SignalSetup|undefined][] = []
        if (data.openTriggerType===StockTriggerType.PREDICTION) {
            fgParts.push('Pred');
        } else {
           sgsList.push(['o',data.openSignalSetup]);
        }
        // close signal for ALL open types
        if (isFull) {
            sgsList.push(['c',data.closeSignalSetup]);
        }
        for (const [p,sgs] of sgsList) {
            const sgsStr = signalsFg(sgs);
            if (sgsStr) {
                fgParts.push(`${p}:${sgsStr}`);
            }
        }

        fgParts.push(`${data.startHourEst}-${data.endHourEst}`);

        fgParts.push(`${rateToStr(data.takeProfitRate, true)}/${rateToStr(data.stopLossRate, true)}`);

        if (data.closeStepSizeRate && isFull) {
            fgParts.push('stepSize:' + rateToStr(data.closeStepSizeRate, false));
        }
        if (data.aheadTimeSecs && isFull) {
            fgParts.push('ahead:'+toMinutes(data.aheadTimeSecs));
        }
        return fgParts.join(' ');
    }
}
