import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ChartHelper } from './ChartHelper';
import * as moment from 'moment-timezone';
import { ChartFilters } from 'components/public/Stock/ChartFilters';
import Form from 'react-bootstrap/Form'
import { TimeZone } from '_constants/TimeZone';
import {DateEx, IQuote, IQuoteFull, IStockDay, StockHelper} from 'predictagram-lib';
import {adminPredictionApiService} from "../../../../services/AdminApiService";
import {IStockData, IStockQuotePartial} from "./ICandlestickLineItem";




export const TodayChart: React.FunctionComponent<{
  symbol: string,
  scaled: boolean,
  isCandleStick:boolean,
  stockData: IStockData,
}> = ({ symbol, scaled, isCandleStick, stockData}) => {

  const currentDate = stockData.date;
  const [chartData, setChartData] = useState<any>(undefined);
  const [options, setOptions] = useState<any>(undefined);
  const [status, setStatus] = useState<any>(undefined);
  const [todayDisplay, setTodayDisplay] = useState<string>('block');
  const [showGraph, setShowGraph] = useState<boolean>(true);
  const [ opacity, setOpacity] = useState<number>(95);
  const [ backgroundColor, setBackgroundColor] = useState<string>('rgba(251, 251, 251, 0.75)');

  const loadData = async (dataDay: IStockData) => {
    try {
      const data = dataDay.data as IQuoteFull[];
      // const lineData = ChartHelper.mergeFullXAxis(data.map(ChartHelper.toLineData), currentDate,  1);
      const lineData = data.map(ChartHelper.toLineData);
      // const linePctData = ChartHelper.mergeFullXAxis(data.map(ChartHelper.deltaPct), currentDate, 1);
      const linePctData = data.map(ChartHelper.deltaPct);

      // const cldData = ChartHelper.mergeFullXAxisCandleStick(data.map(ChartHelper.toCandlestickData), currentDate, 1);
      const cldData = data.map(ChartHelper.toCandlestickData);
      if (!scaled) {
        setChartData([
          {
            name: 'Today',
            type: isCandleStick ? 'candlestick': 'line',
            data: isCandleStick ? cldData :  lineData,
            toolbar: {
              show: false,
            },
          },
        ])
      } else {
        setChartData([
          {
            name: 'Today',
            type: 'line',
            data: linePctData,
            toolbar: {
              show: false,
            },
          },
        ])
      }
    } catch (error: any) {
      setStatus((error as Error).message)
    }

    const _options: ApexCharts.ApexOptions = {
      colors: ['#458ff6'],
      stroke: {
        // dashArray: [5, 10, 10],
        colors: ['#458ff6'],
        width: 1,
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: ['#458ff6'],
          useSeriesColors: false
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'bottomLeft',
        },
      },
      // tooltip: {
      //   // custom: function({series, seriesIndex, dataPointIndex, w}) {
      //   //   return `<div class="mx-3">
      //   //     <span>${series[seriesIndex][dataPointIndex]}</span>
      //   //     </div>`;
      //   // }
      // },
      plotOptions: {
        candlestick: {
          colors: scaled ? {
            upward: '#336699',
            downward: '#336699'
          } : {
            upward: '#00cc00',
            downward: '#cc3300',
          }
        }
      },
      xaxis: {
        labels: {
          show: true,
          hideOverlappingLabels: true,
          // formatter: function (value, timestamp, opts) {
          //   // const t = moment.tz(value, TimeZone.AMERICA_NEW_YORK);
          //   // return ['15:00', '09:30', '12:00'].includes(t.format('HH:mm')) ? t.format('YYYY-MM-DD HH:mm') : '';
          //   return [0, 15, 30, 45].includes(new Date(value).getMinutes()) ? value : '';
          // }
        }
      },
      yaxis: {
        max: dataDay.maxY,
        min: dataDay.minY,
        labels: {
          show: true,
          formatter: (value: number) => {
            let result = '';
            try {
              result = `${parseFloat(value.toString()).toFixed(3)}`;
              return result;
            } catch (error) {
              return '';
            }
          }
        }
      },
      annotations: ChartHelper.annotations(currentDate),
    }

    setOptions(_options);
  }

  const refresh = async() => {
    const newData = await stockData.get();
    // try to keep same scale for chart
    newData.minY = stockData.minY;
    newData.maxY = stockData.maxY;
    await loadData(newData);
  }

  useEffect(() => {
    try {
      loadData(stockData);
    } catch (error) {
      console.error({ error });
    }
    //console.debug({currentDate:currentDate.openAt()});
  }, [symbol, scaled, status, isCandleStick, currentDate])


  const style: React.CSSProperties = {
    display: "block",
  }

  return (
    <div className={`d-${todayDisplay}`} style={ style } >
      <div className='d-flex justify-content-end' style={{ backgroundColor: '#23afaf38' }}>
        <div className="fw-bold py-3 px-3" style={{ backgroundColor: '#23afaf' }} >{symbol} - {moment.tz(new DateEx(currentDate.openAt()*1000), TimeZone.AMERICA_NEW_YORK).format('ddd, YYYY-MM-DD')}</div>
        <div className="mx-3 align-self-center">
          <Form.Check
            type="switch"
            id="today-toggle"
            label="Overlay"
            defaultChecked={showGraph}
            onClick={() => { setShowGraph(!showGraph) }}
          />
        </div>
        {/* <div className="mx-3 align-self-center">
          Opacity:
          <input type="text" style={{width:'50px'}} value={opacity} onChange={onChangeOpacity}/>
        </div> */}
        <div className="mx-3 align-self-center">
          <button className="btn btn-primary" onClick={() => { refresh() }}>Refresh</button>
        </div>
      </div>

      {status &&
        <div style={{ width: "950px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="alert alert-warning p-5" role="status" >
            Could not load today's chart. Try again in a few minutes. API may be unavailable due to delay.
            <p className="m-2">Error: {status}</p>
            <button className="btn btn-primary" onClick={() => setTodayDisplay('none')}>Close</button>
          </div>
        </div>
      }

      {!chartData && !status &&
        <div style={{ width: "950px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="spinner-border align-self-center" role="status">
          </div>
          <span className="align-self-center">Loading {moment.tz(currentDate,TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}</span>
        </div>
      }

      {chartData &&
        <div style={{ backgroundColor: `${backgroundColor}` }} className={`d-${showGraph ? "block" : "none"}`}>
          <ReactApexChart options={options} series={chartData} type="line" height={650} width="1000px" />
        </div>
      }
      <div className="fw-bold py-1" style={{ backgroundColor: '#23afafd9' }}> </div>


    </div>
  );
}
