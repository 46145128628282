export enum SignalAlertCategoryEnum {
    CONTINUATION        = 'Continuation:1',
    OVERBOUGHT          = 'Overbought:1',
    OVERSOLD            = 'Oversold:1',
    REVERSAL            = 'Reversal:1',
    PREDICTIONS_OPEN    = 'PredsOpen:1', // alert depends on predictions
    PREDICTIONS_CLOSE   = 'PredsClose:1',
}

export class SignalAlertCategoryEnumHelper {
    protected static readonly namesMap: Map<SignalAlertCategoryEnum, string> = new Map([
        [SignalAlertCategoryEnum.CONTINUATION,      SignalAlertCategoryEnum.CONTINUATION],
        [SignalAlertCategoryEnum.OVERBOUGHT,        SignalAlertCategoryEnum.OVERBOUGHT],
        [SignalAlertCategoryEnum.OVERSOLD,          SignalAlertCategoryEnum.OVERSOLD],
        [SignalAlertCategoryEnum.REVERSAL,          SignalAlertCategoryEnum.REVERSAL],
        [SignalAlertCategoryEnum.PREDICTIONS_OPEN,  SignalAlertCategoryEnum.PREDICTIONS_OPEN],
        [SignalAlertCategoryEnum.PREDICTIONS_CLOSE,  SignalAlertCategoryEnum.PREDICTIONS_CLOSE],
    ]);

    public static names() {
        return this.namesMap;
    }
}
