import {StrategyFilterOptions} from "components/admin/analysis/strategy-profit/Filter";

import {
  Analysis,
  GroupDirectionType,
  MathEx,
  PredictionTypeEnum,
  SignalCondition,
  SignalItem,
  SignalOptions,
  StockDirectionType as DirectionType,
  StockHelper,
  StockSignalsEnum,
  StockTriggerType
} from "predictagram-lib";
import StockSource = Analysis.StockSource;

export class StrategyProfitModel {
  
  public static stockDay = StockHelper.findTradingDay(new Date(), -1, true);
  public static todayStart = this.stockDay.openAt();
  public static todayEnd = this.stockDay.closeAt();


  public static optionsDefault: SignalOptions = {
    candlesCount: 2,
    rangeCount: 1,
    candleSizePriceRate: 0.001,
    candleSizeRate: 0.5,
    stockGapIntervalSecs: 60 * 5,
    stockGapRate: 0.001,
    repeatedSignal: false,
    volumeSmaCount: 7,
    volumeRate: undefined as any,
  } 

  public static emptySignalItem: SignalItem = {
    cancellationSecs: 0,
    directionTypes: [],
    repeatSecs: 0,
    repeatTypes: [],
    oppositeDirection: false,
    type: '' as StockSignalsEnum,
    exclude: false,
    condition: SignalCondition.AND,
    options: {
      // candlesCount: this.optionsDefault.candlesCount,
      // candleSizePriceRate: this.optionsDefault.candleSizePriceRate,
      // candleSizeRate: this.optionsDefault.candleSizeRate,
      // rangeCount: this.optionsDefault.rangeCount,
      // stockGapIntervalSecs: this.optionsDefault.stockGapIntervalSecs,
      // stockGapRate: this.optionsDefault.stockGapRate
    }
  }

  public static groupDirType = new Map<GroupDirectionType, any>([
    [GroupDirectionType.DIR_SUM, 'SUM'],
    [GroupDirectionType.PERCENTAGE, 'PCT']
  ]);

  public static gapDirection = new Map<DirectionType, any>([
    [DirectionType.UP, 'UP'],
    [DirectionType.DOWN, 'DOWN'],
  ]);


  public static windowTimeSecs = (() => {
    const windowTimeSecs = new Map<any, any>();
    for (let i = 0; i <= 30; i++) {
      windowTimeSecs.set(i * 60, `${i}m`,);
    }
    return windowTimeSecs;
  })()

  public static windowMinMaxPreds = (() => {
    const windowMinPreds = new Map<any, any>([]);
    for (let i = 1; i <= 15; i++) {
      windowMinPreds.set(i, `${i}`);
    }
    return windowMinPreds;
  })();

  public static aheadTimeSecs = (()=>{
    const aheadTimeSecs = new Map<any, any>([]);
    for (let i = 1; i <= 390; i++) {
      aheadTimeSecs.set(i * 60, `${i}m`);
    }
    return aheadTimeSecs;
  })();
  public static takeProfitStopLossRate = (() => {
    const takeProfitRate = new Map<any, any>([]);
    const lossRate = new Map<any, any>([]);
    for (let i=0.0005;i<=0.050;i+=0.0001) {
      //increase step
      if (i>=0.01) {
        i += 0.0009;
      }
      const ir = MathEx.round(i, 4);
      takeProfitRate.set(ir, `${(i*100).toFixed(3)}`);
      lossRate.set(-ir, `-${(i*100).toFixed(3)}`);
    }
    return {lossRate, takeProfitRate}
  })();

  public static directionTypeList = new Map<any, any>([
    [DirectionType.UP, 'UP'],
    [DirectionType.DOWN, 'DOWN'],
  ]);

  public static closeStepSizeRateList = new Map<any, any>([
    [1.4, '140%'],
    [1.2, '120%'],
    [1, '100%'],
    [.8, '80%'],
    [.6, '60%'],
    [.4, '40%'],
    [.2, '20%'],
  ]);

  public static  startHour = new Map<any, any>([
    [8, '8'],
    [9, '9'],
    [10, '10'],
    [11, '11'],
    [12, '12'],
    [13, '13'],
    [14, '14'],
    [15, '15'],
  ]);

  public static endHour = new Map<any, any>([
    [9, '9'],
    [10, '10'],
    [11, '11'],
    [12, '12'],
    [13, '13'],
    [14, '14'],
    [15, '15'],
    [16, '16'],
  ]);

  public static signalIntresectionInterval = new Map([
    [     60, '1m'],
    [2  * 60, '2m'],
    [3  * 60, '3m'],
    [5  * 60, '5m'],
    [10 * 60, '10m'],
    [15 * 60, '15m'],
    [20 * 60, '20m'],
    [30 * 60, '30m'],
    [40 * 60, '40m'],
    [50 * 60, '50m'],
    [60 * 60, '60m'],
  ]);

  public static signalPrevClosePriceThreshold = new Map([
    [0, '0%'],
    [0.0001, '0.010%'],
    [0.00025, '0.025%'],
    [0.0005, '0.05%'],
    [0.00075, '0.075%'],
    [0.001, '0.1%'],
    [0.00125, '0.125%'],
    [0.0015, '0.15%'],
    [0.002, '0.2%'],
    [0.003, '0.3%'],
    [0.004, '0.4%'],
    [0.005, '0.5%'],
    [0.006, '0.6%'],
    [0.007, '0.7%'],
    [0.008, '0.8%'],
    [0.009, '0.9%'],
    [0.010, '1%'],
    [0.011, '1.1%'],
    [0.012, '1.2%'],
    [0.013, '1.3%'],
    [0.014, '1.4%'],
    [0.015, '1.5%'],
    [0.016, '1.6%'],
    [0.017, '1.7%'],
    [0.018, '1.8%'],
    [0.019, '1.9%'],
    [0.020, '2%'],
    [0.025, '2.5%'],
    [0.030, '3%'],
    [0.035, '3.5%'],
    [0.040, '4%'],
    [0.045, '4.5%'],
    [0.050, '5%'],
  ]);

  public static rateRangeAsPct = (start=0, end=1, step=0.1, decimal=0)=>{
    const mp = new Map<number,string>([]);
    for (let i=start;i<=end;i+=step) {
      mp.set(i, MathEx.round(i*100, decimal)+ '%');
    }
    return mp;
  }

  public static range = (start=0, end=1, step=1, decimal=0 ,labelFactor=1)=>{
    const mp = new Map<number,string>([]);
    for (let i=start;i<=end;i+=step) {
      mp.set(i, MathEx.round(i*labelFactor, decimal).toString());
    }
    return mp;
  }

  public static toIntArrayOrDefault(vals: any[], def = []) {
    const data = [];
    for (const v of vals || []) {
      data.push(parseInt(v, 10));
    }
    return data.length ? data : def;
  }

  public static triggerTypeMap = new Map([
    [StockTriggerType.PREDICTION, 'Prediction'],
    [StockTriggerType.SIGNAL, 'Signal'],
  ]);

  public static signalConditionMap = new Map([
    [SignalCondition.AND, 'AND'],
    [SignalCondition.OR, 'OR']
  ]);

  public static signalStrictSequence = new Map([
    [false, 'No'],
    [true, 'Yes']
  ]);

  public static defVals = {
    closeStepSizeRate: null,
    openTriggerType: StockTriggerType.PREDICTION,
    closeSignalSetup: { signals: [this.emptySignalItem], intersectionSecs: this.signalIntresectionInterval.keys().next().value },
    openSignalSetup: { signals: [this.emptySignalItem], intersectionSecs: this.signalIntresectionInterval.keys().next().value},
    startTime: this.todayStart,
    endTime: this.todayEnd,
    stockSymbolId: 3,
    // extraSymbolNames: [] as any,//['AAPL', 'AMZN', 'GOOG', 'SPY', 'QQQ', 'META', 'MSFT', 'NFLX'],
    predictionTypes: [
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
      PredictionTypeEnum.VALUE_1H_UP_DOWN,
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
    ],
    windowTimeSecs: 15 * 60,
    windowMinElements: 1,
    aheadTimeSecs: 240 * 60,
    startHourEst: 9,
    endHourEst: 15,
    takeProfitRate: 0.005,
    stopLossRate: -0.005,
    predictionIds: [],
    groupDirectionType: GroupDirectionType.DIR_SUM,
    extraSymbolIds: [],
    prevCurDayPriceGap: {} as any,
    openTriggerDirectionTypes: [],
    predictionUserIds: undefined,
    windowMaxElements: 1,
    // inputOptions: {
    //   stockSource: StockSource.IBKR,
    // }
    // userAverageScore: {
    //   minScoreRaw: initialValues?.userAverageScore?.minScoreRaw,
    //   maxScoreRaw: initialValues?.userAverageScore?.maxScoreRaw,
    //   days: initialValues?.userAverageScore?.days,
    // }
  } as Analysis.InputParamsEx&Partial<StrategyFilterOptions>;

  static toFixed (v: number, pos: number = 6) {
    return pos == null ? pos : v.toFixed(pos);
  }
}
