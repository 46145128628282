import {AutoTradeSecurityTypeEnum, AutoTradeSecurityTypeEnumHelper} from "predictagram-lib";
import React from "react";
import {DropDownGeneric, DropDownType} from "../../../common/form/DropDownGeneric";

export const SecurityTypeDropDown: React.FunctionComponent<Partial<DropDownType<string,AutoTradeSecurityTypeEnum>>> = ({
   name = 'securityTypeId',
   label = 'Security',
   ...rest
 })=> {
  const options = Array.from(AutoTradeSecurityTypeEnumHelper.names.entries()).map((v,t)=>{
    return {label: v[1] as string,value: v[0] as number}
  });
  options.unshift({label:'All', value: undefined as any});
  // console.debug({options});
  const labelText = ()=>{
    return (
      <div className="fw-bold">{label}</div>
    )
  }
  return (
    <DropDownGeneric {...rest} options={options} label={labelText()} name={name} /*onChange={onChange}*/ />
  );
}

