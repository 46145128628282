import React, {useContext, useEffect, useState} from "react";
import {Spinner} from "components/common/Spinner";
import {
  AnalysisOutputParams, AnalysisTrade,
  AutoTradeSetupStatusEnum, DateHelper,
  IAutoTradeSetup,
  MathEx,
  SignalAlertPerfTypeEnumHelper,
} from "predictagram-lib";
import {
  adminApiServiceCommon,
  adminAutoTradeSetupService,
  adminSignalAlertApiService
} from "../../../../services/AdminApiService";
import { BaseTable } from "../../../common";
import { ApiStateImpl, IApiState } from "../../../../_constants/APIState";
import { MessengerContext, Severity } from "../../../common/messenger";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { ISignalAlert } from "./ISignalAlert";
import {Filter, StrategyFilterOptions, StrategyFilterOptionsWithAlert} from "./Filter";
import { UrlHelper } from "_utils/UrlHelper";
import { StrategyProfitModel as model } from "models/strategy-profit.model";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { getAnalysisInputParams, getLast30dScore, processSearchOptions } from "components/admin/common/Criteria";
import { StorageKey, Url } from "_constants";
import {TradeModel} from "../../../../models/trade.model";
import {ReactTableHelper} from "../../../../_utils/ReactTableHelper";
import {getCoreRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import {DataGrid, DataGridTableColumns} from "../../common/DataGrid";


export enum SubmitModeEnum {
  ANALYZE = 1,
  UPDATE = 2,
  SAVE_AS = 3,
  NEW = 4,
  DAILY_REPORT = 5,
  CREATE_TRADE_SETUP = 6,
  CUME_CHART = 7,
  SAVE_AS_NEW_CHILD = 8,
}
export const AdminAnalysisStrategyProfit: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const signalAlertId = searchParams.get('id');
  const defVals = location.state?.analysisInputParams||model.defVals;

  const [initialValues, setInitialValues] = useState<Partial<StrategyFilterOptions>>(defVals);
  const navigate = useNavigate();

  const [state, setState] = useState<IApiState>(ApiStateImpl.IDLE);
  const [result, setResult] = useState<AnalysisOutputParams>({} as any);

  const loadSignalAlert = async (signalAlertId:number) => {
    const signalAlert: ISignalAlert = await adminSignalAlertApiService.getById(signalAlertId, true);
    const analysisInputParams = getAnalysisInputParams(signalAlert.data as StrategyFilterOptions);
    const initialValues: Partial<StrategyFilterOptionsWithAlert> = {
      ...analysisInputParams,
      alert: signalAlert,
      last30dScore: getLast30dScore(signalAlert.data.userAverageScore)
      // alertName: signalAlert.name,
      // alertEmails: signalAlert.emails,
      // alertSendCloseSignals: signalAlert.options?.sendCloseSignals || false,

    };
    // @ts-ignore
    (initialValues.alert as ISignalAlert).emails = signalAlert.emails.join(',') as any;
    setInitialValues(initialValues);
  };

  useEffect(() => {
    if (signalAlertId === null) {
    }
    else {
      loadSignalAlert(parseInt(signalAlertId));
    }
  }, [signalAlertId])

  const generateReportWrap = async (searchOptions: StrategyFilterOptionsWithAlert, submitMode: SubmitModeEnum) => {
    try {
      await generateReport(searchOptions, submitMode);
    } catch (e: any) {
      setState(ApiStateImpl.error(e));
      msgrContext.setMessage({ body: `An error occurred: ${e.message}` })
    }
  }

  const generateReport = async (searchOptionsIn: StrategyFilterOptionsWithAlert, submitMode: SubmitModeEnum) => {
    const searchOptions: StrategyFilterOptionsWithAlert = Object.assign({}, searchOptionsIn);
    processSearchOptions(searchOptions);

    // analyze form
    if (submitMode === SubmitModeEnum.ANALYZE) {
      const result = await adminApiServiceCommon.analysisStrategyProfit(searchOptions);
      setResult(result);
      setState(ApiStateImpl.LOADED);
      return;
    }
    const sAlert = searchOptions.alert || {};
    // @ts-ignore
    delete searchOptions?.alert;
    // console.debug({sAlert});
    const emails = (sAlert?.emails as any as string||'')?.trim();
    const payload = {
      data: searchOptions as any,
      name: sAlert?.name,
      parentId: sAlert?.parentId||null,
      notes: sAlert?.notes,
      emails: emails!=='' ? emails?.split(',').map(v=>v.trim()) : [],
      categories: sAlert.categories,
      options: {
        sendCloseSignals: !!sAlert.options?.sendCloseSignals,
      }
    } as Partial<ISignalAlert>;

    // new form or save as
    if ([SubmitModeEnum.NEW, SubmitModeEnum.SAVE_AS, SubmitModeEnum.SAVE_AS_NEW_CHILD].includes(submitMode)) {
      const isChild = submitMode===SubmitModeEnum.SAVE_AS_NEW_CHILD;
      if (isChild) {
        payload.parentId = signalAlertId as any || null;
      }
      if (submitMode===SubmitModeEnum.SAVE_AS) {
        payload.parentId = undefined;
      }
      const result = await adminApiServiceCommon.createSignalAlert(payload);
      const msgs = [
        'Alert created #' + result.id
      ];
      if (isChild) {
        // create auto-trade setup too
        const resultSetups = await TradeModel.createTradeSetupFromSignalAlert(result.id, AutoTradeSetupStatusEnum.PAUSED_ENTRY);
        resultSetups.map(resultSetup=>msgs.push(`${TradeModel.getSetupSecurityName(resultSetup)} Trade Setup created # ${resultSetup.id}`));
        // pause parent autoTradeSetup
        const parentSetups = await adminApiServiceCommon.getTradeSetups({filters:{signalAlertIds:[signalAlertId as any]}});
        await Promise.all(parentSetups.map(async(s)=>{
          const update = {statusId: AutoTradeSetupStatusEnum.PAUSED_PARENT} as IAutoTradeSetup;
          if (await adminAutoTradeSetupService.update(update, s.id)) {
            msgs.push('Paused Trade Setup #' + s.id);
          }
        }));
      }


      msgrContext.setMessage({ body: msgs.join(', ') }, true, Severity.NORMAL);
      navigate(UrlHelper.getAdminSignalAlertEdit(result.id));
      return;
    }

    // update existing
    if (submitMode === SubmitModeEnum.UPDATE) {
      if (signalAlertId === null) {
        msgrContext.setMessage({ body: 'Could not update. Missing id' }, true, Severity.FATAL);
        return;
      }
      const result = await adminApiServiceCommon.updateSignalAlert(payload, parseInt(signalAlertId));
      msgrContext.setMessage({ body: 'Alert updated #' + result.id }, true, Severity.NORMAL);
    }

    if (submitMode === SubmitModeEnum.CREATE_TRADE_SETUP) {
      if (signalAlertId === null) { return }
      return navigate(UrlHelper.getAdminAutoTradeSetupFromStrategy(parseInt(signalAlertId)));
    }

    if (submitMode === SubmitModeEnum.DAILY_REPORT) {
      //export to csv file
      const result: any[] = await adminApiServiceCommon.analysisStrategyProfitDaily(searchOptions);
      const rep: any[] = [];

      for (const day of result) {
        const scores: any = day.result.stats.score;
        const amount = scores.profitAmountSum
          + scores.lossAmountSum
          + scores.atCloseAmountSum
          + scores.atExitSignalAmountSum
          + scores.atExitStepSizeAmountSum;
        const totalTrades = scores.totalCount;
        const tradeWinRate = totalTrades ? MathEx.round(day.result.stats.trades.profitable / totalTrades, 4) : 0;
        rep.push({
          date: day.date,
          overallPct: model.toFixed(scores.overall * 100, 4),
          posDirProfitPct: model.toFixed(scores.positiveDirection * 100, 4),
          negDirProfitPct: model.toFixed(scores.negativeDirection * 100, 4),
          trades: totalTrades,
          tradeWinRate: tradeWinRate,
          overallAmount: model.toFixed(amount, 3),
          profitPct: totalTrades ? model.toFixed(scores.profitCount / totalTrades * 100, 2) : 0,
          profitAmount: model.toFixed(scores.profitAmountSum, 3),
          lossPct: totalTrades ? model.toFixed(scores.lossCount / totalTrades * 100, 2) : 0,
          lossAmount: model.toFixed(scores.lossAmountSum, 3),
          closePct: totalTrades ? model.toFixed(scores.atCloseCount / totalTrades * 100, 2) : 0,
          closeAmount: model.toFixed(scores.atCloseAmountSum, 3),
          signalPct: totalTrades ? model.toFixed(scores.atExitSignalCount / totalTrades * 100, 2) : 0,
          signalAmount: model.toFixed(scores.atExitSignalAmountSum, 3),
          stepSizePct: totalTrades ? model.toFixed(scores.atExitStepSizeCount / totalTrades * 100, 2) : 0,
          stepSizeAmount: model.toFixed(scores.atExitStepSizeAmountSum, 3),
        })
      }
      const filename = 'daily-analysis';
      const csv = new ExportToCsv({ useKeysAsHeaders: true, quoteStrings: '', filename });
      csv.generateCsv(rep);

      return;
    }

    if (submitMode === SubmitModeEnum.CUME_CHART && payload.data) {
      localStorage.setItem(StorageKey.CUME_CONFIG, JSON.stringify(payload.data));
      window.open(`${Url.ADMIN_CUMULATIVE_DETAIL}?cached=1`, '_blank');
      return;
    }

  }

  // const formatDir = (type:any,dir:any)=>type==GroupDirectionType.PERCENTAGE ?
  //                                       model.toFixed(dir*100, 2) : dir;
  const formatDir = (a: any, b: any) => b;
  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['groupDirection', ['WithinWindow Dir. Sum', (r: any) => `${formatDir(r.groupDirectionType, r.groupDirection)} (${r.totalCount})`]],

    ['profitRate', ['Profit %', (v) => model.toFixed(v.profitRate * 100, 2)]],
    ['profitAmountAvg', ['Profit $', (v) => model.toFixed(v.profitAmountAvg, 3)]],

    ['lossRate', ['Loss %', (v) => model.toFixed(v.lossRate * 100, 2)]],
    ['lossAmountAvg', ['Loss $', (v) => model.toFixed(v.lossAmountAvg, 3)]],

    ['atCloseRate', ['Close %', (v) => model.toFixed(v.atCloseRate * 100, 2)]],
    ['atCloseAmountAvg', ['Close $', (v) => model.toFixed(v.atCloseAmountAvg, 3)]],

    ['atExitSignalRate', ['Signal %', (v) => model.toFixed(v.atExitSignalRate * 100, 2)]],
    ['atExitSignalAmountAvg', ['Signal $', (v) => model.toFixed(v.atExitSignalAmountAvg, 3)]],

    ['atExitStepSizeRate', ['StepSize %', (v) => model.toFixed(v.atExitStepSizeRate * 100, 2)]],
    ['atExitStepSizeAmountAvg', ['StepSize $', (v) => model.toFixed(v.atExitStepSizeAmountAvg, 3)]],

    ['overallProfitRate', ['EV %', (v) => model.toFixed(v.overallProfitRate * 100, 4)]],
    ['overallProfitAmount', ['EV $', (v) => model.toFixed(v.overallProfitAmount, 3)]]
  ]);

  const columnHelper = ReactTableHelper.createHelper<AnalysisTrade>();
  const columns = [
    columnHelper.columnBuilder('open.time', {
      header: 'Open EST',
      cell: (i)=> DateHelper.dateTimeFormatUs(i.getValue()),
    }).build(),
    columnHelper.columnBuilder('close.time', {
      header: 'Close EST',
      cell: (i)=> DateHelper.dateTimeFormatUs(i.getValue()),
    }).build(),
    columnHelper.columnBuilder('profit.amount', {header: 'Profit $'}).number({fixed:4}).build(),
    columnHelper.columnBuilder('profit.rate', {header: 'Profit %'}).number({fixed:2,percentage:true}).build(),
  ];

  return (
    <div className="strategy-profit mt-3">
      <div className="d-flex justify-content-start justify-content-md-center align-items-start gap-3">
        <Filter onClick={generateReportWrap} initialValues={initialValues} isEditing={signalAlertId !== null} />
      </div>

      {state.isLoading() && <Spinner />}
      {state.isError() && <div>{state.error()?.message}</div>}
      {state.isLoaded() && <>
        <hr />
        <div className="mt-3">
          <div className="d-flex gap-3">
            <div><span className="fw-bold">Final Scores</span></div>
            <div><span className="fw-bold">overall:</span> {model.toFixed(result.stats.score.overall * 100, 4)}%</div>
            <div><span className="fw-bold">pos:</span> {model.toFixed(result.stats.score.positiveDirection * 100, 4)}%</div>
            <div><span className="fw-bold">neg:</span> {model.toFixed(result.stats.score.negativeDirection * 100, 4)}%</div>
            <div><span className="fw-bold">zero:</span> {model.toFixed(result.stats.score.zeroDirection * 100, 4)}%</div>
          </div>
          <div className="d-flex gap-3">
            <div><span className="fw-bold">Trades</span></div>
            <div><span className="fw-bold">total:</span> {result.stats.trades.total}</div>
            <div><span className="fw-bold">pos:</span> {result.stats.trades.positiveDirection}</div>
            <div><span className="fw-bold">neg:</span> {result.stats.trades.negativeDirection}</div>
            <div><span className="fw-bold">zero:</span> {result.stats.trades.zeroDirection}</div>
          </div>
        </div>
        <BaseTable data={result.result as any} fieldList={fieldList} onSelectClick={() => { }} />
        {/*  Render Trades*/}
        {result.trades && <>
          <hr />
          <p className="fw-bold">Trade Details:</p>
          <DataGridTableColumns columns={columns} data={result.trades} sorting={false}/>
        </>}
      </>
      }

      <div className="my-3 mb-5">
        <hr />
        <p className="fw-bold">NOTES:</p>
        <ul>
          <li><b>Prediction Type</b> - Applied to Predictions, Open Signal, Close Signal</li>
          <li><b>Prediction IDs</b> - Comma separated list of predictions to use for all calculations. It's useful to test limited set of data to verify results.</li>
          <li><b>WithinWindow Dir. Sum</b> - Sum of trigger's directions (up/down) within "Window Min" interval. Number in parentheses ("Trades") means the total count of "Window Min" intervals which were used to calculate the average numbers. "0" means only current minute(point in time) is used for calculations. If "current" minute trigger has "UP" direction, but 2 within selected window are "DOWN" - final is "DOWN" direction. It's because net sum: (-1)+(-1)+(+1)=-1</li>
          <li><b>Window Min</b> - number of minutes to look behind prediction's made time to query other predictions.</li>
          <li><b>Last30d Score</b> - User score in last 30d. It's calculated for each prediction individually using time up to (exclusive) prediction made time. The filter is applied for appropriate signals too.</li>
          <li><b>Close By Step Size</b> - Take profit if stock price changes by StepSize*pct amount (direction dependent). StepSize is prediction step size when it's made.</li>
          <li><b>Final Score</b> - Weighted Average for EV and Trades: (netDir1Trades*EvPct1+netDir2Trades*EvPct2.+..+netDirNTrades*EvPctN)/(netDir1Trades+netDir2Trades+..+netDirNTrades).</li>
          <li><b>Step Size First Min Move</b> - Include predictions with StepSize first minute move less than or equal.</li>
          <li><b>Gap Dir. & Gap Diff % </b> - The gap is time range between prev. day close and next day open time. The Gap is the price difference for the range. The Gap has also dir: UP - next is high than prev, DOWN - otherwise.</li>
        </ul>
      </div>
      <div className="my-5 bg-white">&nbsp;</div>
    </div>
  );
};
