import React, {useContext, useEffect, useState} from "react";
import {
  Analysis,
  AnalysisInputParams,
  AutoTradeSecurityTypeEnum,
  IAutoTradeSetup,
  IBKROrderType,
  ISignalAlert,
  OptionType,
} from "predictagram-lib";
import {adminAutoTradeSetupService, adminSignalAlertApiService} from "services/AdminApiService";
import {ApiStateImpl, IApiState} from "_constants/APIState";
import {MessengerContext, Severity} from "components/common/messenger";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SubmitModeEnum, TradeSetupFilterOptions, TradeSetupForm} from "./TradeSetupForm";
import {UrlHelper} from "_utils/UrlHelper";
import {StrategyProfitModel as model} from "models/strategy-profit.model";
import {TradingFormOptions} from "./TradingFormOptions";
import {
  IEquityTradingFormParameters,
  IOptionTradingFormParameters, ITradingFormParameters,
  TradeSetupModel as tradeModel
} from "models/trade-setup.model";
import {TradingFormEquity} from "./TradingFormEquity";
import {getAnalysisInputParams, getLast30dScore, processSearchOptions} from "../common/Criteria";
import {TradeModel} from "models/trade.model";
import {editSignalAlertLink} from "../analysis/alerts/List";
import {AnalysisForm} from "../analysis/strategy-profit/AnalysisForm";
import {Form, Formik} from "formik";

export const TradeSetup: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const [searchParams] = useSearchParams();
  const tradeSetupId = searchParams.get('id');

  const [initialValues, setInitialValues] = useState<Partial<TradeSetupFilterOptions>>({
    ...model.defVals,
    tradeSetupName: '',
    tradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
    quantityPerTrade: undefined,
    maxActiveTrades: undefined,
  });

  const navigate = useNavigate();

  const [state, setState] = useState<IApiState>(ApiStateImpl.IDLE);
  const [analysisInputParams, setAnalysisInputParams] = useState<Analysis.InputParams>();
  const [tradeSecurityType,setTradeSecurityType] = useState<AutoTradeSecurityTypeEnum>(AutoTradeSecurityTypeEnum.OPTION);

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showEquity, setShowEquity] = useState<boolean>(false);

  // const [tradeTemplate, setTradeTemplate] = useState<ITradeTemplate>({} as ITradeTemplate);
  const [optionsFormValues, setOptionsFormValues] = useState<Partial<IOptionTradingFormParameters>>(tradeModel.optionsInitialValues);
  const [equityFormValues, setEquityFormValues] = useState<IEquityTradingFormParameters>(tradeModel.equityInitialValues);

  useEffect(() => {
    if (tradeSetupId === null) {
      const defaultOptionType = TradeModel.getDefaultOptionType(initialValues);
      if (defaultOptionType) {
        setOptionsFormValues({
          ...optionsFormValues,
          optionType: defaultOptionType
        })
      }

    }
    else {
      (async () => {
        const autoTradeSetup: IAutoTradeSetup = await adminAutoTradeSetupService.getById(parseInt(tradeSetupId), true);
        const signalAlert = await adminSignalAlertApiService.getById(autoTradeSetup.signalAlertId) as ISignalAlert;
        const analysisSetup = signalAlert.data;

        const analysisInputParams = getAnalysisInputParams(analysisSetup as TradeSetupFilterOptions);
        setAnalysisInputParams({
          ...analysisInputParams,
          last30dScore: getLast30dScore(analysisSetup.userAverageScore),
        } as Analysis.InputParams);
        const initialValues: Partial<TradeSetupFilterOptions> = {
          ...analysisInputParams,

          last30dScore: getLast30dScore(analysisSetup.userAverageScore),
          tradeSetupName: autoTradeSetup.name,
          signalAlertId: autoTradeSetup.signalAlertId,
          // tradeSetupSignalAlertId: autoTradeSetup.signalAlertId,
          quantityPerTrade: autoTradeSetup.quantityPerTrade,
          maxActiveTrades: autoTradeSetup.maxActiveTrades,
          // tradeSecurityTypeId: tradeSecurityType.current,
        }
        setInitialValues(initialValues);
        setTradeSecurityType(autoTradeSetup.tradeSecurityTypeId);
        const tradeTemplate = autoTradeSetup.tradeTemplate;
        // setTradeTemplate(tradeTemplate);

        const { minExpirationDays, maxExpirationDays, optionType, strikePriceOffset, ibkr } = tradeTemplate;
        const commonFormValues:ITradingFormParameters = {
          ibkr:{
            side: ibkr?.side || tradeModel.optionsInitialValues.ibkr?.side,
            orderType: ibkr?.orderType || tradeModel.optionsInitialValues.ibkr?.orderType || IBKROrderType.LMT_PB,
          },
        };
        if (autoTradeSetup.tradeSecurityTypeId === AutoTradeSecurityTypeEnum.EQUITY) {
          setEquityFormValues({
            ...equityFormValues,
            ...tradeTemplate,
            ...commonFormValues,
          })
        }
        if (autoTradeSetup.tradeSecurityTypeId === AutoTradeSecurityTypeEnum.OPTION) {
          setOptionsFormValues({
            ...optionsFormValues,
            minExpirationDays,
            maxExpirationDays,
            optionType,
            strikePriceOffset,
            ...commonFormValues,
          })
        }
      })()
    }
  }, [tradeSetupId])

  const onDirectionTypeHandler = (direction: "up" | "dn") => {
    setOptionsFormValues({
      ...optionsFormValues,
      optionType: direction === "up" ? OptionType.CALL : OptionType.PUT
    })
  }


  const submitWrap = async (searchOptions: TradeSetupFilterOptions, submitMode: SubmitModeEnum) => {
    try {
      await submit(searchOptions, submitMode);
    } catch (e: any) {
      msgrContext.setMessage({ body: e.message }, true, Severity.FATAL);
      setState(ApiStateImpl.error(e));
    }
  }


  const submit = async (searchOptionsIn: TradeSetupFilterOptions, submitMode: SubmitModeEnum) => {
    const searchOptions: TradeSetupFilterOptions = Object.assign({}, searchOptionsIn);
    processSearchOptions(searchOptions);

    const payload = {
      signalAlertId: searchOptions.signalAlertId,
      // analysisSetup: searchOptions as any,
      name: searchOptions.tradeSetupName,
      //alertEmails: searchOptions.alertEmails,
      tradeSecurityTypeId: tradeSecurityType,
      quantityPerTrade: searchOptions.quantityPerTrade,
      maxActiveTrades: searchOptions.maxActiveTrades,
      maxTradesPerDay: searchOptions.maxTradesPerDay,
      tradeTemplate: tradeSecurityType==AutoTradeSecurityTypeEnum.EQUITY?equityFormValues:optionsFormValues,
    } as IAutoTradeSetup;

    // new form or save as
    if ([SubmitModeEnum.NEW, SubmitModeEnum.SAVE_AS].includes(submitMode)) {
      const result = await adminAutoTradeSetupService.create(payload);
      msgrContext.setMessage({ body: 'Setup page created #' + result.id }, true, Severity.NORMAL);
      navigate(UrlHelper.getAdminAutoTradeSetupEdit(result.id));
      return;
    }

    // update existing
    if (submitMode === SubmitModeEnum.UPDATE) {
      if (tradeSetupId === null) {
        msgrContext.setMessage({ body: 'Could not update. Missing id' }, true, Severity.FATAL);
        return;
      }

      [/*'analysisSetup', 'tradeSecurityTypeId'*/].forEach(i => delete (payload[i])); // remove disallowed fields
      const result = await adminAutoTradeSetupService.update(payload, parseInt(tradeSetupId)); // adminApiServiceCommon.updateTradeSetup(payload, parseInt(tradeSetupId));
      msgrContext.setMessage({ body: 'Setup updated #' + result.id }, true, Severity.NORMAL);
    }

  }

  const optionTradingSubmitHandler = (data: any) => {
    setShowOptions(false);
    setOptionsFormValues(data);
    setTradeSecurityType(AutoTradeSecurityTypeEnum.OPTION);
  }

  const equityTradingSubmitHandler = (data: any) => {
    setShowEquity(false);
    setEquityFormValues(data);
    setTradeSecurityType(AutoTradeSecurityTypeEnum.EQUITY);
  }


  return (
    <>
    <div className="strategy-profit mt-3 d-flex justify-content-center">
      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 ${(showOptions || showEquity) ? 'd-none' : 'd-block'}`}>
        <TradeSetupForm tradeSecurityType={tradeSecurityType} optionsFormValues={tradeSecurityType==AutoTradeSecurityTypeEnum.OPTION?optionsFormValues:equityFormValues} onClick={submitWrap} initialValues={initialValues} isEditing={tradeSetupId !== null} onOpenTriggerDirection={onDirectionTypeHandler} onOpenSignalDirection={onDirectionTypeHandler} />
        <div className="d-flex gap-2 my-3">
          {(!tradeSetupId || tradeSecurityType === AutoTradeSecurityTypeEnum.OPTION) &&
           <button type="button" className="btn btn-primary" onClick={() => setShowOptions(true)}>Set up Options Trade Template</button>
          }
          {(!tradeSetupId || tradeSecurityType === AutoTradeSecurityTypeEnum.EQUITY) &&
           <button type="button" className="btn btn-primary" onClick={() => setShowEquity(true)}>Set up Equity Trade Template</button>
          }
        </div>
      </div>


      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 ${showOptions ? 'd-block' : 'd-none'}`}>
        <TradingFormOptions onCancel={() => setShowOptions(false)} onSubmit={optionTradingSubmitHandler} initialValues={optionsFormValues} />
      </div>

      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 ${showEquity ? 'd-block' : 'd-none'}`}>
        <TradingFormEquity onCancel={() => setShowOptions(false)} onSubmit={equityTradingSubmitHandler} initialValues={equityFormValues} />
      </div>

    </div>


      <div className="strategy-profit mt-3 d-flex justify-content-center">
      <hr />
        <div className="d-flex flex-column justify-content-center align-items-start gap-3 d-block">
          {initialValues.signalAlertId && editSignalAlertLink({id:initialValues.signalAlertId}, 'Edit Alert')}

          {analysisInputParams && initialValues.signalAlertId &&
       <Formik initialValues={analysisInputParams} enableReinitialize onSubmit={(v:any)=>v}>
         {({ values, touched, errors, setFieldValue, isSubmitting }) => {
           return <AnalysisForm initialValues={analysisInputParams as any}
                                values={analysisInputParams as any}
                                errors={{}}
                                setFieldValue={() => true}
                                touched={{}}/>
         }}
       </Formik>
      }
        </div>
    </div>

    </>
  );
};
