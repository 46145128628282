import { IApiResponse, useApi } from "./useApi";
import {IAutoTradeSetup, ISignalAlert} from "predictagram-lib";
import { useState } from "react";
import {adminAutoTradeSetupService, adminSignalAlertApiService} from "services/AdminApiService";

export type IAutoTradeSetupEx = IAutoTradeSetup/*&{signalAlert:ISignalAlert}*/;
export interface IAdminAutoTradeSetupApi {
  api: IApiResponse<IAutoTradeSetupEx[], null>;
  getById: (id: number) => IAutoTradeSetupEx | undefined;
}

export function useAdminAutoTradeSetup (): IAdminAutoTradeSetupApi {
  const [data, setData] = useState<IAutoTradeSetupEx[]>([]);
  const load = async () => {
    const data =  await adminAutoTradeSetupService.getAll(true, true) as IAutoTradeSetupEx[];
    // if (data) {
    //   for (const setup of data) {
    //     setup.signalAlert = await adminSignalAlertApiService.getById(setup.signalAlertId) as ISignalAlert;
    //   }
    // }
    setData(data);
    return data;
  }
  return {
    api: useApi<IAutoTradeSetupEx[], null>(load, null),
    getById: (id: number) => {return data.find(v => v.id === id)}
  }
}

