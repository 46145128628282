import React, {useEffect, useRef, useState} from 'react';
// import { predictionService } from 'services/PredictionService';
import ReactApexChart from 'react-apexcharts';
import {ChartHelper} from './ChartHelper';
import * as moment from 'moment-timezone';
import Form from 'react-bootstrap/Form'
import {TimeZone} from '_constants/TimeZone';
import {DateEx, IQuoteFull, IStockDay} from 'predictagram-lib';
import {IStockData} from "./ICandlestickLineItem";

interface IProps {
  symbol: string,
  stockData: IStockData,
  scaled: boolean, 
  saved: boolean, 
  onToggle: (date: IStockDay, isOn: boolean) => void,
  // fullXAxis?: boolean,
  lazyLoad?: boolean,
  // refresh: number,
  // onLoad?: (day: IStockDay) => void,
  isCandleStick: boolean,
 }


// const isInViewPort = (element: HTMLDivElement): boolean => {
//   const rect = element.getBoundingClientRect();
//   return (
//     rect.top >= 0 &&
//     rect.left >=0 &&
//     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//   );
// }


export const SingleChart: React.FunctionComponent<IProps> = (
  { 
    symbol, scaled, saved, onToggle,
    /*fullXAxis = false,*/ lazyLoad = true,
    isCandleStick =false,
    stockData
  }) => {

  const ref = useRef<HTMLDivElement>(null);
  const svgRef = useRef<HTMLDivElement>(null);
  const currentDate = stockData.date;
  const [options, setOptions] = useState<any>(undefined);
  const [save, setSave] = useState<boolean>(false);
  const [ loaded, setLoaded] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ loadCount, setLoadCount ] = useState<number>(0);
  const [ chartData, setChartData] = useState<any>(undefined);

  useEffect(()=>{
    // if (!loading && ref.current && isInViewPort(ref.current)) {
      //console.log('calling loadData');
      const _load = async () => {
        await loadData();
        // if (svgRef.current) {
        //   console.log(svgRef.current.innerHTML);
        // }
      }
      _load();

    const options: ApexCharts.ApexOptions = {
      colors: ['#000'],
      stroke: {
        // dashArray: [5, 10, 10],
        colors: ['#000'],
        width: 1,
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: ['#458ff6'],
          useSeriesColors: false
        }
      },
      xaxis: {
        labels: {
          show: true,
          hideOverlappingLabels: true,
          // formatter: function (value, timestamp, opts) {
          //   return [0, 15, 30, 45].includes(new Date(value).getMinutes()) ? value : '';
          // }
        }
      },

      yaxis: {
        labels: {
          show: true,
          formatter: (value: number) => {
            try {
              return `${parseFloat(value.toString()).toFixed(3)}`;
            } catch (e) {
              return '';
            }
          }
        },
        min: stockData.minY,
        max: stockData.maxY,
      },
      plotOptions: {
        candlestick: {
          colors: scaled ? {
            upward: '#3C90EB',
            downward: '#3C90EB'
          } : {
            upward: '#3C90EB',
            downward: '#DF7D46'
          }
        }
      },
      annotations: ChartHelper.annotations(currentDate),
    }
    setOptions(options);
    // }
  }, [stockData, scaled] );

  const loadData = () => {
    setLoading(true);
    const mapFunc = isCandleStick ? ChartHelper.toCandlestickData : ChartHelper.toLineData;
    const data = stockData.data as IQuoteFull[];

    const dataPriorDayLine = data.map(ChartHelper.toLineData);
    const dataPriorDayDelta = data.map(ChartHelper.deltaPct);
    // const fullAxisPriorDayLine = ChartHelper.mergeFullXAxis(dataPriorDayLine, 1);
    // const fullAxisPriorDayDelta = ChartHelper.mergeFullXAxis(dataPriorDayDelta, 1);
    // const cldData = ChartHelper.mergeFullXAxisCandleStick(data.map(ChartHelper.toCandlestickData), currentDate, 1);
    const cldData = data.map(ChartHelper.toCandlestickData);
    setChartData([{
      name: 'Today',
      type: isCandleStick?'candlestick':'line',
      // data: !!!today ? ChartHelper.mergeFullXAxis([...prior2Hours, ...today].map(ChartHelper.toLineData), 1) :
      //               scaled ? [...prior2Hours, ...today].map(ChartHelper.deltaPct) : [...prior2Hours, ...today].map(ChartHelper.toLineData),

      data: scaled ? dataPriorDayDelta : (isCandleStick ? cldData : dataPriorDayLine),
    }])

    setLoaded(true);
    setLoading(false);
    // if (onLoad) {
    //   //onLoad(`${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}`);
    //   onLoad(currentDate);
    // }
  }

  // useEffect(() => {

  //   if (!lazyLoad) {
  //     if (!loading && !loaded && ref.current && isInViewPort(ref.current)) {
  //       console.log(`loading ${currentDate.toLocaleDateString()}`);
  //       loadData();
  //     }
  //   }

  //   setLoadCount(loadCount + 1);

  // }, [])

  const toggle = (isOn: boolean) => {
    // const date = moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD');
    // const date = moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD');
    onToggle(currentDate, isOn);
  }
  
  const getSeries = () => {
    // return scaled ? pctChartData : chartData
    return chartData;
  }

  return (
    <>
      {/* <h1>{loadCount}</h1> */}
      <div className='d-flex justify-content-left' style={{backgroundColor: '#007c7c38'}} ref={ref}>
        <div className="fw-bold py-3 px-3 align-self-center" style={{backgroundColor: '#007c7c38'}} >{symbol} - {moment.tz(new DateEx(currentDate.openAt()*1000), TimeZone.AMERICA_NEW_YORK).format('ddd, YYYY-MM-DD')}</div>
        <div className="align-self-center mx-3">
          <Form.Check 
              type="switch"
              id="save-candidate"
              label={`Save as Candidate`}
              defaultChecked={saved}
              onClick={()=>{toggle(!save); setSave(!save);}}
            /></div>
      </div>

      {!getSeries() && !loaded && lazyLoad && 
        <div style={{ width: "900px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="spinner-border align-self-center" role="status">
          </div>
          <span className="align-self-center">Loading {moment.tz(currentDate,TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}</span>
        </div>
      }

      {!getSeries() && loading && 
        <div style={{ width: "900px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="spinner-border align-self-center" role="status">
            <span className="visually-hidden">Loading {moment.tz(currentDate,TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}</span>
          </div>
        </div>
      }

      {getSeries() && options && loaded && <div ref={svgRef}>
        <div id={`${symbol}-${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}`}>
        <ReactApexChart options={options} series={getSeries()} type="line" height={650} width="1000px"/>
        </div>
      </div>
      }

    </>
  );
}
