import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker';


interface IProps {
  onStartDateChange: (date: Date) => void,
  onEndDateChange: (date: Date) => void,
  startDate: Date,
  endDate: Date
}
export const StartEndDateFilter: React.FunctionComponent<IProps> = ({ onStartDateChange, onEndDateChange, startDate, endDate }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(endDate);

  const _onStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
    onStartDateChange(date);
  }

  const _onEndDateChange = (date: Date) => {
    setSelectedEndDate(date);
    onEndDateChange(date);
  }

  return (
      <div className="d-flex justify-content-start align-items-center gap-3">
        <div className="d-flex flex-column">
          <span className="fw-bold">Start Date</span>
          <ReactDatePicker
            dateFormat="MM/dd/yyyy"
            onChange={_onStartDateChange}
            selected={selectedStartDate}
          />
        {/*</div>*/}

        {/*<div className="d-flex flex-column">*/}
          <span className="fw-bold">End Date</span>
          <ReactDatePicker
            dateFormat="MM/dd/yyyy"
            onChange={_onEndDateChange}
            selected={selectedEndDate}
          />
        </div>
      </div>
  )
}
