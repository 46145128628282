import { IPrediction } from "interfaces/IPrediction";
import {DateEx, DateHelper, IQuote, IQuoteFull, IStockDay} from "predictagram-lib";
import {ICandlestickLineItem} from "./ICandlestickLineItem";
import {ILineItem} from "./ILineItem";
import {StockHelper} from "../../../../_utils/stock.helper";

export class ChartHelper {

  /**
   *
   * @param timeSec
   * return 'YYYY-MM-DD HH:mm'
   */
  public static usYyyyMmHhMm(timeSec:number) {
    return DateHelper.dateTimeFormatUs(timeSec).substring(0, 16)
  }

  static toCandlestickData (quote: IQuoteFull) {
    return {
      x: ChartHelper.usYyyyMmHhMm(quote.t).substring(0, 16), // , TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD HH:mm'),
      y: [quote.o, quote.h, quote.l, quote.c],
    }
  }
  
  static toLineData (quote: IQuoteFull) {
    return {
      //x: moment.tz(quote.t*1000, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD HH:mm'),
      x: ChartHelper.usYyyyMmHhMm(quote.t),
      y: quote.c,
    }
  }

  static getFillColor = (quote: IQuoteFull[], prediction: IPrediction) => {
    if (quote.length === 0 || !prediction.value) {
      console.error('could not find point-in-time quote');
      return '#ADF0A2';
    }

    if (prediction.value >= quote[0].c) {
     return '#45f6a9';
    }

    return "#7B3A3A";
    
    // prediction.value && prediction.valueDeviation && 
    // (quote[0].c >= prediction.value - prediction.valueDeviation) &&
    // (quote[0].c <= prediction.value + prediction.valueDeviation) ? "#45f6a9" : "#a7243c";
  }
  
  // static normalize (obj: ILineItem, i: number, data: ILineItem[]) {
  //   const yValues = data.map((item: ILineItem, i: number, data: ILineItem[]) => i > 0 ? data[i].y - data[i - 1].y : 0);
  //   const minY = Math.min(...yValues);
  //   const maxY = Math.max(...yValues);
  //   const deltaPrev = i > 0 ? data[i].y - data[i - 1].y : 0;
  //   return {
  //     x: obj.x,
  //     y: (deltaPrev - minY) / (maxY - minY),
  //   }
  // }

  static normalizeCandlestick (obj: ICandlestickLineItem, i: number, data: ICandlestickLineItem[]) {
    // formula: (deltaPrev - minY) / (maxY - minY)
    // const yValues0 = data.map((item: ICandlestickLineItem, i: number, data: ICandlestickLineItem[]) => i > 0 ? data[i].y[0] - data[i - 1].y[0] : 0);
    // const yValues1 = data.map((item: ICandlestickLineItem, i: number, data: ICandlestickLineItem[]) => i > 0 ? data[i].y[1] - data[i - 1].y[1] : 0);
    // const yValues2 = data.map((item: ICandlestickLineItem, i: number, data: ICandlestickLineItem[]) => i > 0 ? data[i].y[2] - data[i - 1].y[2] : 0);
    // const yValues3 = data.map((item: ICandlestickLineItem, i: number, data: ICandlestickLineItem[]) => i > 0 ? data[i].y[3] - data[i - 1].y[3] : 0);

    // const minY0 = Math.min(...yValues0);
    // const minY1 = Math.min(...yValues1);
    // const minY2 = Math.min(...yValues2);
    // const minY3 = Math.min(...yValues3);

    // const maxY0 = Math.max(...yValues0);
    // const maxY1 = Math.max(...yValues1);
    // const maxY2 = Math.max(...yValues2);
    // const maxY3 = Math.max(...yValues3);

    // const deltaPrev0 = i > 0 ? data[i].y[0] - data[i - 1].y[0] : 0;
    // const deltaPrev1 = i > 0 ? data[i].y[1] - data[i - 1].y[1] : 0;
    // const deltaPrev2 = i > 0 ? data[i].y[2] - data[i - 1].y[2] : 0;
    // const deltaPrev3 = i > 0 ? data[i].y[3] - data[i - 1].y[3] : 0;
    // return {
    //   x: obj.x,
    //   y: [
    //     (deltaPrev0 - minY0) / (maxY0 - minY0),
    //     (deltaPrev1 - minY1) / (maxY1 - minY1),
    //     (deltaPrev2 - minY2) / (maxY2 - minY2),
    //     (deltaPrev3 - minY3) / (maxY3 - minY3),
    //   ]
    // }

    return {
      x: obj.x,
      y: [...obj.y]
    }    


    
  }

  // static delta (obj: ILineItem, i: number, data: ILineItem[])  {
  //   return {
  //     x: obj.x,
  //     y: i > 0 ? (data[i].y - data[i - 1].y)  : 0
  //   }
  // }
  
  static deltaPct (quote: IQuote, i: number, quotes: IQuote[]) {
    return {
      x: ChartHelper.usYyyyMmHhMm(quote.t), // moment.tz(quote.t, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD HH:mm'),
      y: i > 0 ? (quote.c - quotes[0].c) / quotes[0].c * 100  : 0
    }
  }

 
  static deltaPctCandlestick (quote: IQuote, i: number, quotes: IQuote[]) {
    //[quote.o, quote.h, quote.l, quote.c]
    // if (moment.tz(quote.t, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD HH:mm') === '2022-11-16 10:56') {
    //   // console.log({
    //   //   quote,
    //   //   zero: quotes[0],
    //   //   result: {
    //   //     // o: (quote.o - quotes[0].o) / quotes[0].o * 100,
    //   //     // h: (quote.h - quotes[0].h) / quotes[0].h * 100,
    //   //     // l: (quote.l - quotes[0].l) / quotes[0].l * 100,
    //   //     c: (quote.c - quotes[0].c) / quotes[0].c * 100
    //   //   }
    //   // })
    // }
    return {
      x: ChartHelper.usYyyyMmHhMm(quote.t), // moment.tz(quote.t, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD HH:mm'),
      y: i > 0 ? [
        // (quote.o - quotes[0].o) / quotes[0].o * 100,
        // (quote.h - quotes[0].h) / quotes[0].h * 100,
        // (quote.l - quotes[0].l) / quotes[0].l * 100,
        (quote.c - quotes[0].c) / quotes[0].c * 100,
      ]
        : [0, 0, 0, 0]
    }
  }
  
  static xAxisFull (intervalMins:number, stockDay:IStockDay): ILineItem[]  {
    return ChartHelper.xAlixTimeInterval(intervalMins, stockDay).map((t: string) => {return {x: t, y: null}});
  }

  protected static xAlixTimeInterval(intervalMins: number, stockDay: IStockDay) {
    const startDateTime = new DateEx(stockDay.openAt()*1000);

    const times: string[] = [];
    const limit = (60 * 6.5) / intervalMins;

    for (let i = 0; i <= limit; i++) {
      // times.push(moment.tz(startDateTime, TimeZone.AMERICA_NEW_YORK).add(intervalMins * i, 'minutes').format('YYYY-MM-DD HH:mm'));
      times.push(ChartHelper.usYyyyMmHhMm(startDateTime.getTimeSec()+intervalMins * i *60));
    }
    return times;
  }

  static xAxisFullCandlestick(intervalMins: number, stockDay: IStockDay): ICandlestickLineItem[]  {
    return ChartHelper.xAlixTimeInterval(intervalMins, stockDay).map((t: string) => {return {x: t, y: [null, null, null, null]}});
  }


  static mergeFullXAxis (itemsToMerge: ILineItem[], stockDay:IStockDay, intervalMins:number): any {
    const xAxis = ChartHelper.xAxisFull(intervalMins, stockDay);
    const combined = [...xAxis, ...itemsToMerge];
    // use a Map 
    const mapItems: Map<string, ILineItem> = new Map<string, ILineItem>();
    combined.forEach((lineItem: ILineItem) => {
        mapItems.set(lineItem.x, lineItem)
    });
  
    return Array.from(mapItems).map((item: any) => item[1]).sort((a: ILineItem, b: ILineItem) => a.x < b.x ? -1 : 1);
  }

  static mergeFullXAxisCandleStick (itemsToMerge: ICandlestickLineItem[], stockDay:IStockDay, intervalMins:number): any {
    const xAxis = ChartHelper.xAxisFullCandlestick(intervalMins, stockDay);
    const combined = [...xAxis, ...itemsToMerge];
    // use a Map 
    const mapItems: Map<string, ICandlestickLineItem> = new Map<string, ICandlestickLineItem>();
    combined.forEach((lineItem: ICandlestickLineItem) => {
        mapItems.set(lineItem.x, lineItem)
    });
  
    return Array.from(mapItems).map((item: any) => item[1]).sort((a: ILineItem, b: ILineItem) => a.x < b.x ? -1 : 1);
  }
  //
  //
  // static holidays = [
  //   '2018-01-01',
  //   '2018-01-15',
  //   '2018-02-19',
  //   '2018-03-30',
  //   '2018-05-28',
  //   '2018-07-04',
  //   '2018-09-03',
  //   '2018-11-22',
  //   '2018-12-05',
  //   '2018-12-25',
  //   '2019-01-01',
  //   '2019-01-21',
  //   '2019-02-18',
  //   '2019-04-19',
  //   '2019-05-27',
  //   '2019-07-04',
  //   '2019-09-02',
  //   '2019-11-28',
  //   '2019-12-25',
  //   '2020-01-01',
  //   '2020-01-20',
  //   '2020-02-17',
  //   '2020-04-10',
  //   '2020-05-25',
  //   '2020-07-03',
  //   '2020-09-07',
  //   '2020-11-26',
  //   '2020-12-25',
  //   '2021-01-01',
  //   '2021-01-18',
  //   '2021-02-15',
  //   '2021-04-02',
  //   '2021-05-31',
  //   '2021-07-05',
  //   '2021-09-06',
  //   '2021-11-25',
  //   '2021-12-24',
  //   '2022-01-17',
  //   '2022-02-21',
  //   '2022-04-15',
  //   '2022-07-04',
  //   '2022-09-05',
  //   '2022-11-24',
  //   '2022-12-26',
  //   '2023-01-02',
  //   '2023-01-16',
  //   '2023-02-20',
  //   '2023-04-07',
  //   '2023-05-29',
  //   '2023-06-19',
  //   '2023-07-04',
  //   '2023-09-04',
  //   '2023-11-23',
  //   '2023-12-25',
  //   '2024-01-01',
  //   '2024-01-15',
  //   '2024-02-19',
  //   '2024-03-29',
  //   '2024-05-27',
  //   '2024-06-19',
  //   '2024-07-04',
  //   '2024-09-02',
  //   '2024-11-28',
  //   '2024-12-25',
  // ]
  //
  // static halfDays = [
  //   '2023-07-03',
  //   '2024-07-03',
  //   '2025-07-03',
  //   '2026-07-03',
  //   '2023-11-24',
  //   '2024-11-29',
  //   '2025-11-28',
  //   '2026-11-27',
  //   '2024-12-24',
  //   '2024-12-25',
  //   '2024-12-26',
  // ]
  //
  static tradingDaysRange (startDate: DateEx, endDate: DateEx) {
    if (startDate>endDate) {
      throw new Error('invalid range');
    }

    const days:IStockDay[] = [];
    const start = StockHelper.findTradingDay(startDate, +1, true);
    const end = StockHelper.findTradingDay(endDate, -1, true);
    let curr = start;
    while (curr.openAt()<=end.openAt()) {
      days.push(curr);
      curr = curr.nextDay();
    }
    return days;

  }
  //
  // static tradingDays (startDate: string = '2018-01-01', endDate: string = '' ) {
  //   //@TODO: need to handle half-day trading days
  //
  //   const dates = [];
  //   let i: number = 0;
  //   const _endDate = moment.tz(endDate, TimeZone.AMERICA_NEW_YORK).format('YYYYMMDD')
  //   const _today = moment.tz(TimeZone.AMERICA_NEW_YORK).format('YYYYMMDD');
  //
  //   while (moment.tz(startDate, TimeZone.AMERICA_NEW_YORK).add(i, 'day').format('YYYYMMDD') <= (endDate ? _endDate : _today)) {
  //     const date = moment.tz(startDate, TimeZone.AMERICA_NEW_YORK).add(i, 'day');
  //     const shortDay = date.format('ddd').toUpperCase();
  //     i++;
  //     if (['SUN', 'SAT'].includes(shortDay) || this.holidays.includes(date.format('YYYY-MM-DD'))) {
  //       continue;
  //     }
  //     dates.push(date.format('YYYY-MM-DD'));
  //   }
  //
  //   return dates;
  // }
  //
  // static isTradingDay(date: Date = new Date()): boolean {
  //   const mDate = moment.tz(date, TimeZone.AMERICA_NEW_YORK);
  //   return !(['SUN', 'SAT'].includes(mDate.format('ddd').toUpperCase()) || this.holidays.includes(mDate.format('YYYY-MM-DD')) )
  // }
  //
  // static getNextTradingDay(date: Date = new Date()): Date |  undefined {
  //   // find the next market day
  //   const mDate = moment.tz(TimeZone.AMERICA_NEW_YORK);
  //   for (let i=1; i < 6; i++) {
  //     const d = new Date(mDate.add(i, 'days').format());
  //     if (this.isTradingDay(d)) {
  //       return d;
  //     }
  //   }
  //   return undefined;
  // }
  //
  // static isPreMarketHours(date: Date = new Date()): boolean {
  //   const mDate = moment.tz(date, TimeZone.AMERICA_NEW_YORK);
  //   const tm = parseInt(mDate.format('HHmm'));
  //   return this.isTradingDay(date) && tm < 930
  // }
  //
  // static isPostMarketHours(date: Date = new Date()): boolean {
  //   const mDate = moment.tz(date, TimeZone.AMERICA_NEW_YORK);
  //   const tm = parseInt(mDate.format('HHmm'));
  //   return this.isTradingDay(date) && tm > 1600
  // }
  //


  // static findPreviousTradingDay (fromDate: Date, counter?: number): Date | undefined {
  //   if (fromDate.getFullYear() < 2018 ||
  //     moment.tz(fromDate, TimeZone.AMERICA_NEW_YORK).format('YYYYMMDD') > moment.tz(TimeZone.AMERICA_NEW_YORK).format('YYYYMMDD')) {
  //     console.error('findPreviousTradingDay invalid date', {fromDate});
  //     throw Error('invalid date. System supports 2018 onwards.');
  //   }
  //
  //   if (counter && counter > 5) {
  //     console.error('findPreviousTradingDay invalid date', {fromDate});
  //     return undefined;
  //   }
  //
  //   const formattedDate = moment.tz(fromDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD');
  //   const tradingDays = this.tradingDays();
  //   const i = tradingDays.indexOf(formattedDate);
  //   if (i < 0) {
  //       // const prev2 = new Date(moment.tz(fromDate, TimeZone.AMERICA_NEW_YORK).subtract(1, 'day').format());
  //       // const result = this.findPreviousTradingDay(prev2, counter ? counter++ : 1);
  //       console.error('no previous date');
  //       return undefined;
  //   }
  //
  //   if (i > -1) {
  //     //console.log('previous day result', new Date(moment.tz(tradingDays[i - 1], TimeZone.AMERICA_NEW_YORK).format()))
  //     return new Date(moment.tz(tradingDays[i - (counter ? 0 : 1)], TimeZone.AMERICA_NEW_YORK).format());
  //   }
  //
  // }

  static isMarketOpen (date: Date) {
    return StockHelper.isMarketOpen(date);
    // //@TODO: need to handle half-day trading days
    // const mDate = moment.tz(date, TimeZone.AMERICA_NEW_YORK);
    // const dt = mDate.format('YYYY-MM-DD');
    // const tm = parseInt(mDate.format('HHmm'));
    //
    // if (['SUN', 'SAT'].includes(mDate.format('ddd').toUpperCase()) || this.holidays.includes(dt)) {
    //   return false;
    // }
    //
    // if (tm < 930 || tm > (this.halfDays.includes(dt) ? 1300 : 1600) ) {
    //   return false;
    // }
    //
    // return true;
  }
  //
  // static isLastHalfHour (date: Date) {
  //   const mDate = moment.tz(date, TimeZone.AMERICA_NEW_YORK);
  //   const dt = mDate.format('YYYY-MM-DD');
  //   const tm = parseInt(mDate.format('HHmm'));
  //
  //   return (this.isMarketOpen(date) && tm >= 1530 && tm <= (this.halfDays.includes(dt) ? 1300 : 1600));
  // }

  static isToday = (date: Date) => {
    const today = new Date();
    return `${date.getFullYear()}${date.getMonth()}${date.getDate()}` === `${today.getFullYear()}${today.getMonth()}${today.getDate()}`
  }

  static annotations(currentDate: IStockDay) {
    const dateStr = this.usYyyyMmHhMm(currentDate.openAt()).split(' ')[0];
    return {
      xaxis: [
        {x: `${dateStr} 09:30`, borderColor: '#000000',},
        {x: `${dateStr} 12:00`, borderColor: '#000000',},
        {x: `${dateStr} 14:30`,borderColor: '#000000',},
      ]
    }
  }
}
