import { useAdminAutoTradeSetupSingle } from '_hooks/useAdminAutoTradeSetupSingle';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import React, { useEffect, useRef } from 'react'
import { SignalItem } from './SignalItem';
import { LinkAdminTradeSetup } from './LinkAdminTradeSetup';
import useEscape from '_hooks/useEscape';

export const ExitOverlay: React.FunctionComponent<{tradeSetupId: number, onEscape: ()=>void}> = ({tradeSetupId, onEscape}) => {
  
  const api = useAdminAutoTradeSetupSingle(tradeSetupId).api;
  const divRef = useRef<HTMLDivElement | null>(null);

  useEscape(() => {
    onEscape()
  });


  useEffect(()=>{
    api.setFilter(tradeSetupId);
  }, [tradeSetupId])


  const render = () => {
    if (api.apiState.isLoading) {
      return <Spinner minHeight={10} />
    }

    if (api.apiState.isError) {
      return <ErrorMessage className="bg-white">Error: {api.error}</ErrorMessage>
    }

    if (api.apiState.isLoaded) {

      const setup = api.data.signalAlert.data;
      const aheadTimeSecs = setup.aheadTimeSecs;
      const closeStepSizeRate = setup.closeStepSizeRate;
      const closeSignalSetup = setup.closeSignalSetup;
      const stopLossRate = setup.stopLossRate;
      const takeProfitRate = setup.takeProfitRate;
      const last30dScore = (setup as any)?.last30dScore;


      return <>
        <div className="fw-bold text-14 d-flex gap-2">{api.data.name} <LinkAdminTradeSetup tradeSetupId={tradeSetupId} /></div>
        <table className="table text-12">
          <thead>
            <tr>
              <th>Ahead Min</th>
              <th>Last30D Score</th>
              <th>Take Profit %</th>
              <th>Stop Loss %</th>
              <th>Step Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{aheadTimeSecs/60}</td>
              <td>{last30dScore}</td>
              <td>{(takeProfitRate * 100).toFixed(3)}</td>
              <td>{(stopLossRate * 100).toFixed(3)}</td>
              <td>{closeStepSizeRate}</td>
            </tr>

            <tr>
              <td colSpan={5}>
                <div className="fw-bold">Signals</div>
                <div className="d-flex flex-row gap-2">
                <div style={{height: "200px", overflowY: "auto"}}>{closeSignalSetup?.signals && closeSignalSetup.signals.map((s) => <SignalItem signalItem={s} />)}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      
      </>
    }

    return <></>
  }

  return (
    <div className="admin-exit-overlay" ref={divRef}>
      {render()}
    </div>
  );
}
